import { useContext, useEffect, useState } from "react";
import State from "../../../../../../context";
import { Button, Input, Row, Space, Table, theme } from "antd";
import AddAdvisorModal from "./components/AddAdvisorModal";
import ButtonDemoMode from "../../../../../../atom/ButtonDemoMode";
import MenuActions from "../../../../../../atom/MenuActions";
import ModalDeleteAdvisor from "../../../../component/ModalDeleteAdvisor";
import SectionAdvisorsAnalytic from "../SectionAdvisorsAnalytic";

import StylesContainer from "./styles";

import {
  deleteAdvisor,
  openAdvisorView,
} from "../../../../../../utils/request/manager";
import { getCurrencyValue } from "../../../../../../utils/helper/general";
import {
  getManagedUsersTotalProposedAmount,
  isAdminView,
} from "../../../../../../utils/helper/specialized";

import OpenInNew from "../../../../../../icon/OpenInNew";

const getColumns = ({
  searchedText,
  handleEditAction,
  handleDeleteAction,
  setActiveAdvisorEmail,
  organization,
}) => [
  {
    title: "Advisor",
    dataIndex: "advisor",
    key: "advisor",
    sorter: (a, b) => a.advisor.localeCompare(b.advisor),
    render: (text, restValues) => (
      <a onClick={() => setActiveAdvisorEmail(restValues.email)}>
        <b>{text}</b>
      </a>
    ),
    filteredValue: [searchedText],
    onFilter: (value, record) =>
      String(record.advisor).toLowerCase().includes(value.toLowerCase()) ||
      String(record.email).toLowerCase().includes(value.toLowerCase()),
  },
  {
    dataIndex: "email",
    key: "email",
    render: value => <a href={`mailto:${value}`}>{value}</a>,
    sorter: (a, b) => a.email.localeCompare(b.email),
    title: "Email",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: "Broker/Dealer Name",
    dataIndex: "brokerName",
    key: "brokerName",
    sorter: (a, b) => a.brokerName - b.brokerName,
  },
  {
    title: "Firm Name",
    dataIndex: "firmName",
    key: "firmName",
    sorter: true,
  },
  {
    title: "Proposed AUM",
    dataIndex: "proposedAum",
    key: "proposedAum",
    sorter: (a, b) => a.proposedAum.localeCompare(b.proposedAum),
  },
  {
    title: "Clients",
    dataIndex: "clients",
    key: "clients",
    sorter: (a, b) => a.clients - b.clients,
  },
  {
    title: "Proposals",
    dataIndex: "proposals",
    key: "proposals",
    sorter: (a, b) => a.proposals - b.proposals,
  },
  {
    title: "",
    dataIndex: "actionsMenu",
    key: "actionsMenu",
    fixed: "right",
    width: 40,
    className: "actions-menu-table-cell",
    render: (value, restValues) => (
      <MenuActions
        items={getItems({
          email: restValues.email,
          handleEditAction,
          handleDeleteAction,
          organization,
        })}
      />
    ),
  },
];

const getItems = ({
  email,
  handleEditAction,
  handleDeleteAction,
  organization,
}) => [
  {
    key: "1",
    label: "Edit Advisor",
    onClick: () => handleEditAction(email),
  },
  {
    key: "2",
    label: (
      <Space>
        Open Advisor View
        <OpenInNew />
      </Space>
    ),
    onClick: () => openAdvisorView(email, organization),
  },
  {
    type: "divider",
  },
  {
    key: "3",
    label: <span style={{ color: "red" }}>Delete Advisor</span>,
    onClick: () => handleDeleteAction(email),
  },
];

const ViewAdvisors = ({ setActiveAdvisorEmail }) => {
  const { token } = theme.useToken();
  const [state] = useContext(State);
  const [advisorsTableData, setAdvisorsTableData] = useState();
  const [searchedText, setSearchedText] = useState("");
  const [deletedAdvisorEmail, setDeletedAdvisorEmail] = useState();
  const [editedAdvisorEmail, setEditedAdvisorEmail] = useState();

  useEffect(() => {
    if (state.managedUserManagers?.length) {
      setAdvisorsTableData(
        state.managedUserManagers.map(
          ({ userManager: advisorData, firstName, lastName = " ", email }) => {
            const convertedClients = advisorData.managedUsers?.filter(
              it =>
                !it.isDeleted &&
                (it.userType === "Registered" ||
                  it.userType === "ConvertedProspect")
            );

            const clientsWithProposal = convertedClients?.filter(
              it => it.proposalSavedDate || it.valueMap?.productMap
            );

            return {
              key: email,
              advisor: firstName ? firstName + " " + lastName : lastName,
              email,
              type: advisorData.userType ?? "",
              brokerName: advisorData.brokerDealerName ?? "",
              firmName: advisorData.firmName ?? "",
              proposedAum: getCurrencyValue(
                getManagedUsersTotalProposedAmount(clientsWithProposal)
              ),
              clients: convertedClients?.length ?? 0,
              proposals: clientsWithProposal?.length ?? 0,
              actionsMenu: { email },
            };
          }
        )
      );
    } else {
      setAdvisorsTableData();
    }
  }, [state]);

  const handleEditAction = advisorEmail => {
    setEditedAdvisorEmail(advisorEmail);
    state.openModal("addAdvisorModal");
  };

  const handleDeleteAction = advisorEmail => {
    setDeletedAdvisorEmail(advisorEmail);
    state.openModal("deleteAdvisorModal");
  };

  const handleOpenAddAdvisor = () => {
    setEditedAdvisorEmail(null);
    state.openModal("addAdvisorModal");
  };

  const handleDeleteAdvisor = () => {
    state.setKeyValue("loading", true);

    deleteAdvisor(deletedAdvisorEmail)
      .then(response => {
        state.setAdminData().then(() => {
          state.showSuccess(response.data);
          state.closeModal("deleteAdvisorModal");
          setDeletedAdvisorEmail(null);
        });
      })
      .catch(error => {
        state.setKeyValue("loading", false);
        state.showError(error.response?.data);
      });
  };

  return (
    <StylesContainer>
      <SectionAdvisorsAnalytic />
      <Row justify="space-between" align="middle" style={{ margin: "28px 0" }}>
        <Input
          style={{
            maxWidth: 290,
            height: 37,
            borderRadius: 25,
          }}
          placeholder="Search Advisors..."
          onChange={event => setSearchedText(event.target.value)}
        />
        <Space size={15}>
          <ButtonDemoMode
            organization={state.organization?.name}
            userManagerEmail={state._id}
          />
          <Button
            disabled={state.loading || isAdminView()}
            onClick={handleOpenAddAdvisor}
            shape="round"
            size="small"
            style={{
              width: 118,
              color: token.button_primary_text_color,
              backgroundColor: token.button_primary_background,
            }}
          >
            + Add Advisor
          </Button>
        </Space>
      </Row>
      <Table
        dataSource={advisorsTableData}
        columns={getColumns({
          searchedText,
          handleEditAction,
          handleDeleteAction,
          setActiveAdvisorEmail,
          organization: state.organization?.name,
        })}
        pagination={false}
        loading={state.loading}
        scroll={{
          x: 1450,
        }}
      />

      <AddAdvisorModal
        open={state.addAdvisorModal}
        handleClose={() => state.closeModal("addAdvisorModal")}
        editedAdvisorEmail={editedAdvisorEmail}
      />
      <ModalDeleteAdvisor
        email={deletedAdvisorEmail}
        handleOk={handleDeleteAdvisor}
        loading={state.loading}
        onCancel={() => state.closeModal("deleteAdvisorModal")}
        open={state.deleteAdvisorModal}
      />
    </StylesContainer>
  );
};

export default ViewAdvisors;
