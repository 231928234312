import { Outlet, Route, Routes } from "react-router-dom";
import GlobalStateProvider from "./StateProvider";
import GoalsLayout from "../ecosystem/goals/layouts";
import LayoutManager from "../layout/LayoutManager";
import LayoutPrimary from "../layout/LayoutPrimary";
import LayoutProposal from "../layout/LayoutProposal";
import MmiAdminStateProvider from "../ecosystem/mmi/context/admin/StateProvider";
import MmiAssetManagerLayout from "../ecosystem/mmi/templates/PortalAssetManager/layouts";
import MmiUserStateProvider from "../ecosystem/mmi/context/user/StateProvider";
import UI from "./UI";

import {
  isDomainAstor,
  isDomainBluepoint,
  isDomainHalo,
  isDomainLevel,
  isDomainMmi,
  isDomainNewland,
  isDomainOneascent,
  isDomainRivershares,
  isDomainSymetra,
} from "../utils/helper/specialized";

import astorAdminPrivateRoutes from "../ecosystem/astor/routes/admin";
import astorAdvisorPrivateRoutes from "../ecosystem/astor/routes/advisor";
import astorProposalPrivateRoutes from "../ecosystem/astor/routes/proposal";
import astorPublicRoutes from "../ecosystem/astor/routes/public";
import bluepointAdminPrivateRoutes from "../ecosystem/bluepoint/routes/admin";
import bluepointGoalsUserPrivateRoutes from "../ecosystem/bluepoint/routes/goals";
import bluepointProposalPrivateRoutes from "../ecosystem/bluepoint/routes/proposal";
import bluepointPublicRoutes from "../ecosystem/bluepoint/routes/public";
import goalsUserPrivateRoutes from "../ecosystem/goals/routes/user";
import haloPublicRoutes from "../ecosystem/halo/routes/public";
import levelAdminPrivateRoutes from "../ecosystem/level/routes/admin";
import levelAdvisorPrivateRoutes from "../ecosystem/level/routes/advisor";
import levelPublicRoutes from "../ecosystem/level/routes/public";
import levelProposalPrivateRoutes from "../ecosystem/level/pages/PageLevelProposal/routes/user";
import mmiAdminPrivateRoutes from "../ecosystem/mmi/routes/admin";
import mmiPublicRoutes from "../ecosystem/mmi/routes/public";
import mmiUserPrivateRoutes from "../ecosystem/mmi/routes/user";
import newlandAdminPrivateRoutes from "../ecosystem/newland/routes/admin";
import newlandGoalsUserPrivateRoutes from "../ecosystem/newland/routes/goals";
import newlandProposalPrivateRoutes from "../ecosystem/newland/routes/proposal";
import newlandPublicRoutes from "../ecosystem/newland/routes/public";
import nextAdvisorRoutes from "../ecosystem/next/routes";
import oneAscentAdminPrivateRoutes from "../ecosystem/oneascent/route/admin";
import oneAscentAdvisorPrivateRoutes from "../ecosystem/oneascent/route/advisor";
import oneAscentProposalPrivateRoutes from "../ecosystem/oneascent/route/proposal";
import oneAscentPublicRoutes from "../ecosystem/oneascent/route/public";
import publicRoutes from "../ecosystem/goals/routes/public";
import riversharesAdminPrivateRoutes from "../ecosystem/rivershares/routes/admin";
import riversharesAdvisorPrivateRoutes from "../ecosystem/rivershares/routes/advisor";
import riversharesProposalPrivateRoutes from "../ecosystem/rivershares/routes/proposal";
import riversharesPublicRoutes from "../ecosystem/rivershares/routes/public";
import symetraPublicRoutes from "../ecosystem/symetra/routes/public";
import superAdminPrivateRoutes from "../ecosystem/super_admin/routes/admin";

import themeBasic from "../theme/global";
import themeUserAstor from "../ecosystem/astor/themes/user";
import themeUserBluepoint from "../ecosystem/bluepoint/themes/user";
import themeUserNewland from "../ecosystem/newland/theme/user";
import themeUserOneascent from "../ecosystem/oneascent/theme/user";

const getPublicRoutes = () => {
  if (isDomainAstor()) return astorPublicRoutes;
  else if (isDomainBluepoint()) return bluepointPublicRoutes;
  else if (isDomainNewland()) return newlandPublicRoutes;
  else if (isDomainHalo()) return haloPublicRoutes;
  else if (isDomainLevel()) return levelPublicRoutes;
  else if (isDomainMmi()) return mmiPublicRoutes;
  else if (isDomainOneascent()) return oneAscentPublicRoutes;
  else if (isDomainRivershares()) return riversharesPublicRoutes;
  else if (isDomainSymetra()) return symetraPublicRoutes;
  else return publicRoutes;
};

const getPublicThemeConfig = () => {
  if (isDomainAstor()) return themeUserAstor;
  else if (isDomainBluepoint()) return themeUserBluepoint;
  else if (isDomainNewland()) return themeUserNewland;
  else if (isDomainOneascent()) return themeUserOneascent;
  else return themeBasic;
};

const Routing = () => {
  const children = Object.entries(publicRoutes)
    .map(([path, Component]) => ({
      path,
      element: <Component key={path} />,
    }))
    .map(props => <Route key={props.path} {...props} />);

  return (
    <Routes>
      {Object.entries(getPublicRoutes()).map(([path, LayoutOutlet]) => (
        <Route
          key={path}
          path={path}
          element={
            <UI themeConfig={getPublicThemeConfig()}>
              <LayoutOutlet />
            </UI>
          }
        >
          {children}
        </Route>
      ))}
      <Route
        element={
          <GlobalStateProvider manager organization="Level">
            <LayoutManager routes={levelAdminPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {levelAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="Level">
            <LayoutManager routes={levelAdvisorPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {levelAdvisorPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="Astor">
            <LayoutPrimary routes={astorAdminPrivateRoutes}>
              <Outlet />
            </LayoutPrimary>
          </GlobalStateProvider>
        }
      >
        {astorAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="OneAscent">
            <LayoutManager routes={oneAscentAdminPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {oneAscentAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="Bluepoint">
            <LayoutManager routes={bluepointAdminPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {bluepointAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="Newland">
            <LayoutManager routes={newlandAdminPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {newlandAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="RiverShares">
            <LayoutManager routes={riversharesAdminPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {riversharesAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="Astor">
            <LayoutPrimary routes={astorAdvisorPrivateRoutes}>
              <Outlet />
            </LayoutPrimary>
          </GlobalStateProvider>
        }
      >
        {astorAdvisorPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="OneAscent">
            <LayoutManager routes={oneAscentAdvisorPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {oneAscentAdvisorPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="RiverShares">
            <LayoutManager routes={riversharesAdvisorPrivateRoutes}>
              <Outlet />
            </LayoutManager>
          </GlobalStateProvider>
        }
      >
        {riversharesAdvisorPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization="Level">
            <LayoutPrimary routes={levelProposalPrivateRoutes}>
              <Outlet />
            </LayoutPrimary>
          </GlobalStateProvider>
        }
      >
        {levelProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization="Goals">
            <GoalsLayout routes={goalsUserPrivateRoutes}>
              <Outlet />
            </GoalsLayout>
          </GlobalStateProvider>
        }
      >
        {goalsUserPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization="Bluepoint">
            <GoalsLayout routes={bluepointGoalsUserPrivateRoutes}>
              <Outlet />
            </GoalsLayout>
          </GlobalStateProvider>
        }
      >
        {bluepointGoalsUserPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization="Newland">
            <GoalsLayout routes={newlandGoalsUserPrivateRoutes}>
              <Outlet />
            </GoalsLayout>
          </GlobalStateProvider>
        }
      >
        {newlandGoalsUserPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization="Astor">
            <LayoutProposal routes={astorProposalPrivateRoutes}>
              <Outlet />
            </LayoutProposal>
          </GlobalStateProvider>
        }
      >
        {astorProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization="OneAscent">
            <LayoutProposal routes={oneAscentProposalPrivateRoutes}>
              <Outlet />
            </LayoutProposal>
          </GlobalStateProvider>
        }
      >
        {oneAscentProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization="Bluepoint">
            <LayoutProposal routes={bluepointProposalPrivateRoutes}>
              <Outlet />
            </LayoutProposal>
          </GlobalStateProvider>
        }
      >
        {bluepointProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization="Newland">
            <LayoutProposal routes={newlandProposalPrivateRoutes}>
              <Outlet />
            </LayoutProposal>
          </GlobalStateProvider>
        }
      >
        {newlandProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider organization="RiverShares">
            <LayoutProposal routes={riversharesProposalPrivateRoutes}>
              <Outlet />
            </LayoutProposal>
          </GlobalStateProvider>
        }
      >
        {riversharesProposalPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <MmiUserStateProvider>
            <MmiAssetManagerLayout>
              <Outlet />
            </MmiAssetManagerLayout>
          </MmiUserStateProvider>
        }
      >
        {mmiUserPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <MmiAdminStateProvider>
            <Outlet />
          </MmiAdminStateProvider>
        }
      >
        {mmiAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager organization="Penny">
            <LayoutPrimary routes={superAdminPrivateRoutes}>
              <Outlet />
            </LayoutPrimary>
          </GlobalStateProvider>
        }
      >
        {superAdminPrivateRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
      <Route
        element={
          <GlobalStateProvider manager>
            <LayoutPrimary>
              <Outlet />
            </LayoutPrimary>
          </GlobalStateProvider>
        }
      >
        {nextAdvisorRoutes.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Route>
    </Routes>
  );
};

export default Routing;
