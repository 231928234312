import { useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { Button, Flex, theme, Tooltip } from "antd";
import DashboardCompare from "../../../../../../organism/DashboardCompare";
import ModalConfirmSaveProposal from "../ModalConfirmSaveProposal";
import ModalGoalSettings from "../../../../../goals/components/ModalGoalSettings";
import ModalProductDetails from "../../../../../../organism/ModalProductDetails";
import ModalRelatedProducts from "../../../../../goals/components/ModalRelatedProducts";
import ProposalGoalDashboard from "../ProposalGoalDashboard";
import CardSkeleton from "../../../../../../atom/CardSkeleton";

import StylesContainer from "./styles";

import {
  DEFAULT_ADVISOR_FEE,
  DEFAULT_BIRTH_YEAR,
  DEFAULT_RETIREMENT_AGE,
  DEFAULT_START_WITHDRAWAL_YEAR,
  DEFAULT_WITHDRAWAL_LEVEL,
} from "../../../../../../utils/constant";
import { PORTFOLIO_X_RAY_LIST } from "../../../../../oneascent/constant";
import {
  PROPOSAL_DEFAULT_DATA_LEVEL,
  PROPOSAL_SAMPLE_URL_LEVEL,
} from "../../../../constants";

import {
  downloadProposal,
  getLiveAssessment,
} from "../../../../../../utils/request/regularApp";
import {
  calculateWithdrawalLevelFromWithdrawalAmount,
  convertCurrentAgeRange,
  convertInvestmentDurationRange,
  getLastSavedProposalProps,
  getManuallyUpdatedOAGoalRecommendedProduct,
  isDemoProposalUser,
  isGrowthTypeRiskTolerance,
  isLockedByAdminView,
  isOrganizationLevel,
  isOrganizationOneascent,
} from "../../../../../../utils/helper/specialized";
import {
  cleanPercentValue,
  getPercentValue,
} from "../../../../../../utils/helper/general";
import { getRecommendedProductId } from "./helpers";

import { DownloadOutlined } from "@ant-design/icons";
import { ReactComponent as IconClose } from "../../../../images/icon_close.svg";

const ViewProposalGoal = ({
  activeGoal,
  handleCloseGoal,
  handleDownloadProposal,
  productsList,
}) => {
  const { token } = theme.useToken();
  const [state, setState] = useContext(State);
  const [compareMode, setCompareMode] = useState(false);
  const [confirmSaveDialogOpened, setConfirmSaveDialogOpened] = useState(false);
  const [proposalData, setProposalData] = useState(null);
  const [proposalDirty, setProposalDirty] = useState(false);
  const [isCompareChartDirty, setIsCompareChartDirty] = useState(false);

  useEffect(() => {
    if (
      state.proposalViewMode === "pdf" &&
      proposalData &&
      state.userManagerData
    ) {
      const interval = setInterval(() => {
        const barsChartSvg = localStorage.getItem("barsChartSvg");
        const growthChartSvg = localStorage.getItem("growthChartSvg");
        const pieChartSvg = localStorage.getItem("pieChartSvg");
        const pieChartWithLegendSvg = localStorage.getItem(
          "pieChartWithLegendSvg"
        );

        if (growthChartSvg && pieChartSvg && pieChartWithLegendSvg) {
          downloadProposal({
            barsChartSvg: barsChartSvg,
            guideInitialData: state.getPreferenceValue("guideInitialData"),
            growthChartSvg: growthChartSvg,
            insufficientFundsStartingIn: state.insufficientFundsStartingIn,
            pieChartSvg: pieChartSvg,
            pieChartWithLegendSvg: pieChartWithLegendSvg,
            proposalData: proposalData,
            userManagerData: state.userManagerData,
            userName: `${state.personalInfo.firstName} ${state.personalInfo.lastName}`,
          });

          state.setKeyValue("proposalViewMode", "goals");
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [proposalData, state.proposalViewMode]);

  useEffect(() => {
    if (!state.loading && state._id) {
      if (!proposalData) {
        const prospectObjective = state.getPreferenceValue("prospectObjective");
        const productMap = state.getPreferenceValue("productMap");

        if (activeGoal && productMap) {
          const activeGoalLastSavedProps =
            productMap[activeGoal][productMap[activeGoal].length - 1];

          setProposalData({
            ...activeGoalLastSavedProps,
            birthYear: activeGoalLastSavedProps.currentAge
              ? undefined
              : activeGoalLastSavedProps.birthYear,
            contributions:
              activeGoalLastSavedProps.contributions ??
              activeGoalLastSavedProps.savingsEachMonth * 12,
            lengthOfInvestment: undefined,
            retirementAge:
              state.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            withdrawalLevel:
              activeGoalLastSavedProps.withdrawalLevel > 100
                ? Math.round(
                    (activeGoalLastSavedProps.withdrawalLevel /
                      activeGoalLastSavedProps.investmentAmount) *
                      100
                  )
                : activeGoalLastSavedProps.withdrawalLevel,
          });
        } else if (productMap) {
          const lastSavedProposalProps = getLastSavedProposalProps(productMap);
          const prospectObjective =
            state.getPreferenceValue("prospectObjective");

          setProposalData({
            ...prospectObjective,
            birthYear: prospectObjective.currentAge
              ? undefined
              : prospectObjective.birthYear,
            lengthOfInvestment: undefined,
            retirementAge:
              state.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            timestamp: undefined,
            withdrawalLevel:
              lastSavedProposalProps.withdrawalLevel > 100
                ? Math.round(
                    (lastSavedProposalProps.withdrawalLevel /
                      lastSavedProposalProps.investmentAmount) *
                      100
                  )
                : lastSavedProposalProps.withdrawalLevel,
          });
        } else if (prospectObjective) {
          const investmentDuration = isNaN(prospectObjective.investmentDuration)
            ? convertInvestmentDurationRange(
                prospectObjective.investmentDuration
              )
            : +prospectObjective.investmentDuration;

          const currentAge =
            prospectObjective.currentAge ??
            convertCurrentAgeRange(prospectObjective.currentAgeRange);

          setProposalData({
            ...prospectObjective,
            advisorFee: prospectObjective.advisorFee ?? DEFAULT_ADVISOR_FEE,
            birthYear: currentAge
              ? undefined
              : prospectObjective.birthYear || DEFAULT_BIRTH_YEAR,
            currentAge,
            incomeChartType: isGrowthTypeRiskTolerance(
              prospectObjective.investmentObjective
            )
              ? "contributions"
              : "income",
            investmentDuration,
            lengthOfInvestment: undefined,
            retirementAge:
              state.getPreferenceValue("guideInitialData")?.retirementAge ??
              DEFAULT_RETIREMENT_AGE,
            withdrawalLevel:
              prospectObjective.withdrawalLevel > 100
                ? calculateWithdrawalLevelFromWithdrawalAmount({
                    contributions: prospectObjective.contributions,
                    fee: cleanPercentValue(prospectObjective.advisorFee),
                    investmentAmount: prospectObjective.investmentAmount,
                    riskTolerance: prospectObjective.riskTolerance,
                    withdrawalAmount: prospectObjective.withdrawalLevel,
                    yearToStartWithdrawal:
                      prospectObjective.yearToStartWithdrawals ??
                      Math.round(investmentDuration / 2),
                  })
                : (prospectObjective.withdrawalLevel ??
                  DEFAULT_WITHDRAWAL_LEVEL),
            yearToStartWithdrawals:
              prospectObjective.yearToStartWithdrawals ||
              Math.round(investmentDuration / 2) ||
              DEFAULT_START_WITHDRAWAL_YEAR,
          });
        } else {
          // onboarding quiz skipped
          setProposalData(PROPOSAL_DEFAULT_DATA_LEVEL);
        }
      }
    }
  }, [state]);

  useEffect(() => {
    if (proposalData) {
      const recommendedProductId =
        state.selectedProduct ??
        proposalData.recommendedProductId ??
        proposalData.productId ??
        getRecommendedProductId({
          organization: state.organization?.name ?? state.orgName,
          productsList,
          proposalData,
        });

      if (recommendedProductId) {
        setState(lastState => ({
          ...lastState,
          selectedProduct: recommendedProductId,
          compareProducts: [recommendedProductId],
        }));
      }
    }
  }, [productsList, proposalData, state.proposalViewMode]);

  useEffect(() => {
    if (!activeGoal) {
      setProposalDirty(true);
    }
  }, [activeGoal]);

  const handleCardClick = cardId => {
    state.setKeyValue("productDetailsId", cardId);
    state.openModal("productDetailsModal");
  };

  const handleProposalDataChange = useDebouncedCallback(
    (key, value, skipDirty) => {
      if (!isLockedByAdminView(state.showWarning)) {
        !skipDirty && setProposalDirty(true);

        setProposalData(lastState => ({
          ...lastState,
          [key]: value,
        }));
      }
    },
    500
  );

  const handleSaveProposal = () => {
    state.setKeyValue("loading", true);

    getLiveAssessment({
      objective: {
        ...proposalData,
        email: state._id,
        investmentDuration:
          proposalData?.investmentDuration ?? proposalData?.lengthOfInvestment,
        productId: state.selectedProduct,
        timestamp: undefined,
      },
      organization: state.organization?.name?.toLowerCase(),
      productId: state.selectedProduct,
      saveAction: activeGoal ? "PUT" : "POST",
      saveId: activeGoal,
      activeGoal,
    })
      .then(() => {
        setProposalDirty(false);
        state.showSuccess("Proposal saved");
        setConfirmSaveDialogOpened(false);
        setTimeout(() => {
          state.setUserData().then(data => {
            if (activeGoal) {
              //update existed active goal
              if (data.preferences?.valueMap?.productMap) {
                setProposalData(
                  data.preferences.valueMap.productMap[activeGoal][
                    data.preferences.valueMap.productMap[activeGoal].length - 1
                  ]
                );
              }
            } else {
              //set new created goal ID as active goal
              const createdGoalId = Object.keys(
                data.preferences.valueMap.productMap
              )[Object.keys(data.preferences.valueMap.productMap).length - 1];

              state.setKeyValue("activeGoal", createdGoalId);
              setProposalData(
                data.preferences.valueMap.productMap[createdGoalId][
                  data.preferences.valueMap.productMap[createdGoalId].length - 1
                ]
              );
            }
            state.setKeyValue("loading", false);
          });
        }, 1000);
      })
      .catch(({ response }) => {
        state.setKeyValue("loading", false);
        state.showError(
          response?.data?.error ?? response?.data?.message?.error
        );
      });
  };

  const handleUpdateGoal = updatedProposalData => {
    setProposalData(updatedProposalData);
    state.closeModal("goalSettingsModal");
    state.showSuccess("Goal settings updated");
    setProposalDirty(true);

    const recommendedProduct = getManuallyUpdatedOAGoalRecommendedProduct({
      customRiskTolerance: updatedProposalData.riskTolerance,
      currentSelectedProduct: productsList.find(
        it => it._id === state.selectedProduct
      ),
      productsList,
    });

    if (recommendedProduct) {
      setState(lastState => ({
        ...lastState,
        compareProducts: [recommendedProduct._id],
        selectedProduct: recommendedProduct._id,
      }));
    }
  };

  const defaultListCardAction = [
    {
      buttonActiveCopy: "Added to Proposal",
      buttonCopy: "Add to Proposal",
      key: "addToProposal",
      onSelect: product => {
        state.closeModal("relatedProductsModal");
        const newProductData = productsList.find(it => it._id === product._id);

        if (newProductData) {
          handleProposalDataChange(
            "riskTolerance",
            newProductData.riskTolerance
          );
        }

        setState(lastState => ({
          ...lastState,
          compareProducts: [product._id],
          selectedProduct: product._id,
        }));
      },
    },
  ];

  const compareListCardAction = [
    {
      buttonActiveCopy: "Selected to Compare",
      buttonCopy: "Add to Compare",
      key: "addToCompare",
      onSelect: product => {
        setState(lastState => ({
          ...lastState,
          compareProducts: lastState.compareProducts.includes(product._id)
            ? lastState.compareProducts.filter(id => id !== product._id)
            : [...lastState.compareProducts, product._id],
        }));

        setIsCompareChartDirty(true);
        state.closeModal("relatedProductsModal");
      },
    },
  ];

  const handleDownloadProposalClick = () => {
    if (isOrganizationLevel(state.organization?.name ?? state.orgName)) {
      handleDownloadProposal(state.activeGoal);

      // TODO migrate other tenants into new PDF generator
      return;
    }

    setState(lastState => ({
      ...lastState,
      activeGoal,
      productDetailsId: state.selectedProduct,
    }));

    localStorage.removeItem("barsChartSvg");
    localStorage.removeItem("growthChartSvg");
    localStorage.removeItem("pieChartSvg");
    localStorage.removeItem("pieChartWithLegendSvg");

    state.setKeyValue("proposalViewMode", "pdf");
    state.openModal("productDetailsModal");
  };

  return (
    <StylesContainer token={token}>
      <header>
        <div className="title">
          {compareMode ? (
            "Compare Products"
          ) : (
            <span>
              Level: <b>Investment Proposal</b>
            </span>
          )}
        </div>
        {compareMode ? (
          <Flex align="end">
            <Button
              icon={<IconClose />}
              onClick={() => setCompareMode(false)}
              shape="round"
            >
              Exit Compare Mode
            </Button>
          </Flex>
        ) : (
          !isDemoProposalUser(state._id) && (
            <Flex align="end" gap={22} wrap="wrap">
              {!proposalDirty && proposalData?.timestamp ? (
                <>
                  <Flex align="end" vertical>
                    <span style={{ fontSize: 12, color: token.color_grey_1 }}>
                      Created
                    </span>
                    <span style={{ fontSize: 12, color: token.color_black }}>
                      {dayjs(proposalData.timestamp).format("MM/DD/YYYY h:mma")}
                    </span>
                  </Flex>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={handleDownloadProposalClick}
                    shape="round"
                  >
                    Download Proposal
                  </Button>
                  <Button
                    icon={<IconClose />}
                    onClick={handleCloseGoal}
                    shape="round"
                    type="primary"
                  >
                    Close
                  </Button>
                </>
              ) : (
                <>
                  <Tooltip title="Please save the proposal before closing">
                    <Button
                      disabled={true}
                      icon={<IconClose />}
                      onClick={handleCloseGoal}
                      shape="round"
                    >
                      Close
                    </Button>
                  </Tooltip>
                  <Button
                    disabled={isLockedByAdminView({
                      managerAccess: state.managerAccess,
                    })}
                    onClick={() => setConfirmSaveDialogOpened(true)}
                    shape="round"
                    type="primary"
                  >
                    Save Proposal
                  </Button>
                </>
              )}
            </Flex>
          )
        )}
        {isDemoProposalUser(state._id) &&
          isOrganizationLevel(state.organization?.name ?? state.orgName) && (
            <Button
              icon={<DownloadOutlined />}
              onClick={() => window.open(PROPOSAL_SAMPLE_URL_LEVEL)}
              shape="round"
              style={{
                background: token.tenant_color_primary,
                color: "#FFFFFF",
                borderColor: "transparent",
              }}
            >
              Download Proposal
            </Button>
          )}
      </header>

      <div className="body">
        <CardSkeleton loading={state.loading}>
          {compareMode ? (
            <DashboardCompare
              closeCompareMode={() => setCompareMode(false)}
              handleCardClick={handleCardClick}
              isCompareChartDirty={isCompareChartDirty}
              openProductsListModal={() =>
                state.openModal("relatedProductsModal")
              }
              productsList={productsList}
              proposalData={proposalData}
              setIsCompareChartDirty={setIsCompareChartDirty}
              setState={setState}
              showError={state.showError}
              showSecondaryButton={true}
              state={state}
            />
          ) : (
            <ProposalGoalDashboard
              activeGoal={activeGoal}
              handleCardClick={handleCardClick}
              handleProposalDataChange={handleProposalDataChange}
              openCompareMode={() => setCompareMode(true)}
              productsList={productsList}
              proposalData={proposalData}
            />
          )}

          <ModalConfirmSaveProposal
            handleClose={() => setConfirmSaveDialogOpened(false)}
            handleSaveProposal={handleSaveProposal}
            isFirstSave={
              !(state.getPreferenceValue("productMap") ?? [])[activeGoal]
            }
            open={confirmSaveDialogOpened}
          />
          <ModalProductDetails
            handleClose={() => state.closeModal("productDetailsModal")}
            hideMcSettingsToggler={true}
            investmentAssumptions={{}}
            open={state.productDetailsModal}
            organization={state.organization?.name}
            product={{
              ...productsList.find(it => it._id === state.productDetailsId),
              ...proposalData,
              advisorFee: getPercentValue(proposalData?.advisorFee),
              investmentDuration:
                proposalData?.investmentDuration ??
                proposalData?.lengthOfInvestment,
              withdrawalLevel: getPercentValue(
                proposalData?.withdrawalLevel ?? 0
              ),
            }}
            productsList={productsList}
            xRayList={
              isOrganizationOneascent(state.organization?.name) &&
              PORTFOLIO_X_RAY_LIST
            }
          />
        </CardSkeleton>
      </div>

      <ModalGoalSettings
        handleClose={() => state.closeModal("goalSettingsModal")}
        handleUpdateGoal={handleUpdateGoal}
        loading={state.loading}
        open={state.goalSettingsModal}
        organization={state.organization?.name}
        proposalData={proposalData}
      />
      <ModalRelatedProducts
        cardActions={
          compareMode ? compareListCardAction : defaultListCardAction
        }
        compareProducts={state.compareProducts}
        favoriteFinancialProducts={state.favoriteFinancialProducts}
        handleCardClick={handleCardClick}
        handleClose={() => state.closeModal("relatedProductsModal")}
        hideFavoriteProducts={isOrganizationOneascent(state.organization?.name)}
        open={state.relatedProductsModal}
        productsList={productsList}
        selectedProduct={state.selectedProduct}
      />
    </StylesContainer>
  );
};

export default ViewProposalGoal;
