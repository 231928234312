import StylesContainer from "./styles";

import { LEAD_MAX_RATE_VALUE } from "../../../../../level/constants";

const LeadsAnalyticsSection = ({ managedUsers = [] }) => {
  const totalLeadsList =
    managedUsers.filter(
      it =>
        it.userType === "Affiliated" ||
        it.userType === "Assigned" ||
        it.userType === "Lead"
    ) ?? [];

  const getLeadsConverted = () =>
    managedUsers.reduce(
      (acc, it) => acc + (it.valueMap?.leadInitial?.isConverted || 0),
      0
    ) ?? 0;

  const getLeadsRating = () => {
    const assignedLeads = totalLeadsList.filter(
      it =>
        it.entryPoint === "addedByOrgManager" &&
        !it.valueMap?.leadInitial?.isConverted
    );

    const totalLeadsRate = assignedLeads?.reduce(
      (acc, it) => acc + it.valueMap?.leadInitial?.rate || 0,
      0
    );

    return totalLeadsRate
      ? (
          (totalLeadsRate / (assignedLeads.length * LEAD_MAX_RATE_VALUE)) *
          LEAD_MAX_RATE_VALUE
        ).toFixed(2)
      : 0;
  };

  return (
    <StylesContainer gap={24}>
      <div className="value-container">
        <b>{totalLeadsList.length}</b>
        <span>Total Active Leads</span>
      </div>
      <div className="value-container">
        <b>{getLeadsConverted()}</b>
        <span>Leads Converted</span>
      </div>
      <div className="value-container">
        <b>{getLeadsRating()}</b>
        <span>Average Lead Rating</span>
      </div>
    </StylesContainer>
  );
};

export default LeadsAnalyticsSection;
