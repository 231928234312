import dayjs from "dayjs";
import { Image } from "antd";

import StylesContainer from "./styles";

import logo from "./image/logo.svg";

const ReportPageCover = ({ managerName, managerPhone, personaName }) => (
  <StylesContainer id="reportPageCover">
    <div className="page-container">
      <header>
        <Image className="logo" preview={false} src={logo} />
      </header>

      <main>
        <h2>Personalized Proposal</h2>
        <p className="label">Prepared for</p>
        <h3>{personaName}</h3>
        <p className="date">{dayjs().format("MMMM DD, YYYY")}</p>
      </main>

      <footer>
        <p className="label">Prepared by:</p>
        <p className="label">
          <b>{managerName}</b>
        </p>
        {managerPhone && <p className="label">Phone: {managerPhone}</p>}
      </footer>
    </div>
  </StylesContainer>
);

export default ReportPageCover;
