import dayjs from "dayjs";

import { PENDO_HOSTNAMES_LIST } from "../constant";
import { DEFAULT_PROPOSAL_INITIALIZATION_DATA } from "../../ecosystem/oneascent/constant";

import { updateProductObjective } from "../request/regularApp";
import { openProposal } from "../request/manager";
import {
  capitalizeString,
  getCurrencyValue,
  getImageDataURL,
  getPercentValue,
  setHeadAttributes,
  setPhoneMask,
} from "./general";

import favicon_astor from "../../ecosystem/astor/images/favicon.ico";
import favicon_level from "../../ecosystem/level/images/favicon.ico";
import favicon_mmi from "../../ecosystem/mmi/images/favicon.ico";
import favicon_newland from "../../ecosystem/newland/image/favicon.ico";
import favicon_oneascent from "../../ecosystem/oneascent/image/favicon.png";
import favicon_rivershares from "../../ecosystem/rivershares/images/favicon.ico";

export const addDynamicScript = config => {
  const script = document.createElement("script");
  if (config.id) script.id = config.id;
  if (config.src) script.src = config.src;
  if (config.async) script.async = config.async;
  document.body.appendChild(script);
};

export const calculateWithdrawalLevelFromWithdrawalAmount = ({
  contributions,
  fee,
  investmentAmount,
  riskTolerance,
  withdrawalAmount,
  yearToStartWithdrawal,
}) => {
  let interestRate;

  if (riskTolerance === "Preservation") interestRate = 3.01;
  else if (riskTolerance === "Conservative") interestRate = 4.97;
  else if (riskTolerance === "Moderate") interestRate = 6.92;
  else if (riskTolerance === "Growth") interestRate = 8.82;
  else interestRate = 10.82;

  const asDecimal = percentage => percentage / 100;

  const FV_initial = (
    interestRate,
    investmentAmount,
    fee,
    yearToStartWithdrawal
  ) =>
    Math.round(
      investmentAmount *
        (1 + (asDecimal(interestRate) - asDecimal(fee))) **
          yearToStartWithdrawal
    );

  const FV_annuities = (
    interestRate,
    contributions,
    fee,
    yearToStartWithdrawal
  ) =>
    Math.round(
      contributions *
        (((1 + (asDecimal(interestRate) - asDecimal(fee))) **
          yearToStartWithdrawal -
          1) /
          (asDecimal(interestRate) - asDecimal(fee)))
    );

  const FV_total =
    FV_initial(interestRate, investmentAmount, fee, yearToStartWithdrawal) +
    FV_annuities(interestRate, contributions, fee, yearToStartWithdrawal);

  const getPercentage = (withdrawalAmount, FV_total) =>
    ((withdrawalAmount / FV_total) * 100).toFixed(2);

  return getPercentage(withdrawalAmount, FV_total);
};

export const convertCurrentAgeRange = rangeValue => {
  switch (rangeValue) {
    case "-45":
      return 40;
    case "45-55":
      return 45;
    case "56-65":
      return 56;
    case "66-75":
      return 66;
    case "75-":
      return 75;
    default:
      return undefined;
  }
};

export const convertCurrentAgeNumber = value => {
  switch (value) {
    case 40:
      return "-45";
    case 45:
      return "45-55";
    case 56:
      return "56-65";
    case 66:
      return "66-75";
    case 75:
      return "75-";
    default:
      return undefined;
  }
};

export const convertInvestmentDurationRange = rangeValue => {
  switch (rangeValue) {
    case "1-5":
      return 5;
    case "6-10":
      return 10;
    case "11-20":
      return 20;
    case "20-":
      return 25;
  }
};

export const convertYearToStartWithdrawalsRange = rangeValue => {
  switch (rangeValue) {
    case "-3":
      return 1;
    case "3-5":
      return 4;
    case "5-10":
      return 6;
    case "10-":
      return 11;
  }
};

export const getYearToStartWithdrawalsLabel = rangeValue => {
  switch (rangeValue) {
    case "-3":
      return "0 - 3 years";
    case "3-5":
      return "3 - 5 years";
    case "5-10":
      return "5 - 10 years";
    case "10-":
      return "More than 10 years";
  }
};

export const genDataForPDF = ({
  barsChartSvg,
  growthChartSvg,
  guideInitialData,
  insufficientFundsStartingIn,
  pieChartSvg,
  pieChartWithLegendSvg,
  proposalData,
  userManagerData,
  userName,
}) => {
  if (!proposalData) return;
  const {
    investmentAmount,
    investmentDuration,
    investmentObjective,
    lengthOfInvestment,
    riskTolerance,
    advisorFee = "1%",
    withdrawalLevel = "5%",
    productName,
    timestamp,
    yearToStartWithdrawals,
    calculated,
  } = proposalData;

  const growthChartB64 = getImageDataURL(growthChartSvg);
  const barsChartB64 = getImageDataURL(barsChartSvg);
  const pieChartB64 = getImageDataURL(pieChartSvg);
  const pieChartWithLegendSvgB64 = getImageDataURL(pieChartWithLegendSvg);

  const requestBody = JSON.stringify({
    page1: {
      preparedFor: `${userName}${
        guideInitialData?.isMarried
          ? " & " +
            guideInitialData.spouseFirstName +
            " " +
            guideInitialData.spouseLastName
          : ""
      }`,
      preparedBy:
        userManagerData.personalInfo?.firstName +
        " " +
        userManagerData.personalInfo?.lastName,
      preparedByFirm: userManagerData?.firmName,
      preparedByPhone: setPhoneMask(userManagerData.personalInfo?.phoneNumber),
      date: dayjs(timestamp).format("MM/DD/YYYY"),
      advisorEmail: userManagerData._id,
    },
    page2: {
      name:
        (userManagerData.personalInfo?.firstName ?? "") +
        " " +
        (userManagerData.personalInfo?.lastName ?? ""),
      street: userManagerData.personalInfo?.street ?? "",
      city: userManagerData.personalInfo?.city ?? "",
      state: userManagerData.personalInfo?.state ?? "",
      zipCode: userManagerData.personalInfo?.zipCode ?? "",
      email: "Email: " + userManagerData._id,
    },
    page3: {
      investmentObjective,
      advisorFee: advisorFee,
      income: {
        whatWeTalkedAbout: capitalizeString(investmentObjective),
        mainChart: growthChartB64,
        piechart: pieChartB64,
        pieChartWithLegend: pieChartWithLegendSvgB64,
        selections: {
          initialInvestment: getCurrencyValue(investmentAmount),
          insufficientFundsStartingIn,
          lengthOfInvestment:
            investmentDuration === "20-"
              ? "20+"
              : (investmentDuration ?? lengthOfInvestment),
          objective: capitalizeString(investmentObjective),
          riskTolerance: capitalizeString(riskTolerance),
          selectionChart: barsChartB64,
          yearToStartWithdrawals: yearToStartWithdrawals,
          withdrawalAmount: getCurrencyValue(calculated?.withdrawalAmount) ?? 0,
          withdrawalRate: getPercentValue(withdrawalLevel),
        },
      },
      growth: {
        whatWeTalkedAbout: capitalizeString(investmentObjective),
        mainChart: growthChartB64,
        piechart: pieChartB64,
        selections: {
          objective: capitalizeString(investmentObjective),
          initialInvestment: getCurrencyValue(investmentAmount),
          riskTolerance: capitalizeString(riskTolerance),
          lengthOfInvestment:
            investmentDuration === "20-" ? "20+" : investmentDuration,
        },
      },
    },
    page5: {
      riskTolerance: capitalizeString(riskTolerance),
      productName: productName,
    },

    page6: {
      yourExpectationsOfIncome: proposalData?.incomeExpectations,
      whenDoYouPlantoBeginWithdrawal: proposalData?.beginWithdrawal,
      whichStatementBestDescribesYour: proposalData?.investmentKnowledge,
      anInvestmentDecisionInvolvesBothReturnsAndRisk:
        proposalData?.decisionInfluence,
      understandingThatVolatility: proposalData?.volatilityTolerance,
      whenTheNextDownturn: proposalData?.decliningMarketWithholdingPeriod,
      currentAgeRange:
        convertCurrentAgeNumber(proposalData?.currentAge) ??
        proposalData?.currentAgeRange,
      investmentAmount: getCurrencyValue(proposalData?.investmentAmount),
      investmentDuration: proposalData?.investmentDuration,
      contributions: getCurrencyValue(proposalData?.contributions),
      householdIncome: getCurrencyValue(proposalData?.householdIncome),
      prospectType: proposalData?.prospectType,
      note: proposalData?.note,
      acceptedRiskLevel: proposalData?.acceptedRiskLevel !== "custom",
      listAccounts: proposalData?.listAccounts,
    },
    page7: {
      yourCalculatedRisk: capitalizeString(proposalData?.riskTolerance),
      youSelectedAnInvestment: capitalizeString(
        proposalData?.investmentObjective
      ),
    },
  });

  localStorage.setItem("personalizedProposalData", requestBody);
};

export const getAllManagedClientsOfAdvisors = managedUserManagers => {
  return managedUserManagers
    ? managedUserManagers
        .filter(it => it.userManager.managedUsers)
        .map(({ firstName, lastName, userManager, email: advisorEmail }) => {
          const {
            managedUsers,
            brokerDealerName,
            userType: advisorType,
            firmName,
            lastLoginTime,
          } = userManager;

          return managedUsers
            .filter(
              it =>
                !it.isDeleted &&
                (it.userType === "Registered" ||
                  it.userType === "ConvertedProspect")
            )
            .map(it => {
              return {
                ...it,
                advisorName: firstName ? firstName + " " + lastName : lastName,
                advisorEmail,
                advisorType,
                brokerDealerName,
                firmName,
                lastLoginTime:
                  lastLoginTime?.$date &&
                  dayjs(lastLoginTime.$date).format("MM/DD/YYYY"),
              };
            });
        })
        .flat()
    : [];
};

export const getAllManagedProspectsOfAdvisors = managedUserManagers => {
  return managedUserManagers
    ? managedUserManagers
        .filter(it => it.userManager.managedUsers)
        .map(({ firstName, lastName, userManager, email: advisorEmail }) => {
          const {
            managedUsers,
            brokerDealerName,
            userType: advisorType,
            firmName,
            lastLoginTime,
          } = userManager;

          return managedUsers
            .filter(
              it =>
                !it.isDeleted &&
                (it.userType === "Prospect" ||
                  (it.entryPoint === "addedByUserManager" &&
                    it.userType === "Assigned"))
            )
            .map(it => {
              return {
                ...it,
                advisorName: firstName ? firstName + " " + lastName : lastName,
                advisorEmail,
                advisorType,
                brokerDealerName,
                firmName,
                lastLoginTime:
                  lastLoginTime?.$date &&
                  dayjs(lastLoginTime.$date).format("MM/DD/YYYY"),
              };
            });
        })
        .flat()
    : [];
};

export const getAllManagedUsersOfAdvisors = managedUserManagers =>
  managedUserManagers
    ? managedUserManagers
        .filter(it => it.userManager.managedUsers)
        .map(({ firstName, lastName, userManager, email: advisorEmail }) => {
          const {
            managedUsers,
            brokerDealerName,
            userType: advisorType,
            firmName,
            lastLoginTime,
          } = userManager;

          return managedUsers
            .filter(it => !it.isDeleted)
            .map(it => {
              return {
                ...it,
                advisorName: firstName ? firstName + " " + lastName : lastName,
                advisorEmail,
                advisorType,
                brokerDealerName,
                firmName,
                lastLoginTime:
                  lastLoginTime?.$date &&
                  dayjs(lastLoginTime.$date).format("MM/DD/YYYY"),
              };
            });
        })
        .flat()
    : [];

export const getGoalIdByGoalName = (productMap, goalName) => {
  if (productMap && goalName) {
    let goalId;

    Object.keys(productMap).map(id => {
      if (
        (productMap[id][0].goalName ?? productMap[id][0].productName) ===
        goalName
      ) {
        goalId = id;
      }
    });

    return goalId && goalId;
  }
};

export const getGoalFundedPercent = ({
  calculationsData,
  goalData,
  scenarioData,
}) => {
  if (!goalData || !scenarioData || !calculationsData) {
    return 0;
  }

  let goalValue;
  let currentProgressValue;

  if (scenarioData?.progress) {
    currentProgressValue = getLatestProgressValue(scenarioData?.progress);
  } else {
    const currentYear = +dayjs().format("YYYY");
    const goalStartYear = scenarioData.properties.startYear;

    if (currentYear === goalStartYear) {
      currentProgressValue = goalData.investmentAmount;
    } else {
      const currentUserAge =
        currentYear - scenarioData.properties.users[0].startAge;

      currentProgressValue = calculationsData.grandTotalColumn.find(
        it => it[0] === currentUserAge
      )[1];
    }
  }

  if (goalData.targetAmount) {
    goalValue = goalData.targetAmount;
  } else if (!calculationsData.gFutureProgress?.length) {
    goalValue = (calculationsData.grandTotalColumn.find(
      it => it[0] === scenarioData.properties.retirementAge
    ) ??
      calculationsData.grandTotalColumn[
        calculationsData.grandTotalColumn.length - 1
      ])[1];
  } else if (goalData.goalType === "income") {
    goalValue = (calculationsData.grandTotalColumn.find(
      ([age]) => age === scenarioData.properties.retirementAge + 1
    ) ??
      calculationsData.grandTotalColumn[
        calculationsData.grandTotalColumn.length - 1
      ])[1];
  } else {
    goalValue = getLastOriginalProgressValue(calculationsData);
  }

  const dirtyGoalFundedPercent = Math.round(
    (currentProgressValue / goalValue) * 100
  );

  return dirtyGoalFundedPercent > 100
    ? 100
    : dirtyGoalFundedPercent < 0
      ? 0
      : dirtyGoalFundedPercent;
};

export const getGoalProgressLabel = ({ goalData, calculationsData }) => {
  if (
    !calculationsData?.grandTotalColumn?.length ||
    !calculationsData?.gOT?.progressDataForPlotting?.length
  ) {
    return "";
  }

  if (goalData.targetAmount) {
    const latestActualProgressData =
      calculationsData.gOT.progressDataForPlotting.sort(
        (a, b) => b[0] - a[0]
      )[0];

    const latestActualProgressAmount = latestActualProgressData[1];

    if (latestActualProgressAmount === 0) {
      return "";
    }

    const latestActualProgressAge = Math.ceil(latestActualProgressData[0]);
    const relatedCalculatedAmount = (calculationsData.grandTotalColumn.find(
      it => it[0] === latestActualProgressAge
    ) ?? calculationsData.grandTotalColumn[0])[1];

    if (latestActualProgressAmount > relatedCalculatedAmount) {
      return "On track!";
    } else {
      return "Off track!";
    }
  } else {
    const lastFutureProgressValue =
      getLastFutureProgressValue(calculationsData);
    const lastCalculatedValue =
      calculationsData.grandTotalColumn[
        calculationsData.grandTotalColumn.length - 1
      ][1];

    if (lastFutureProgressValue > lastCalculatedValue) {
      return "On track!";
    } else {
      return "Off track!";
    }
  }
};

export const getLatestProgressValue = progress =>
  progress
    ? progress.sort(
        (a, b) => new Date(b.date.$date) - new Date(a.date.$date)
      )[0].actualTotal
    : 0;

export const getLastFutureProgressValue = calculationsData =>
  calculationsData.gFutureProgress.length
    ? calculationsData.gFutureProgress[
        calculationsData.gFutureProgress.length - 1
      ][1]
    : 0;

export const getLastOriginalProgressValue = calculationsData =>
  calculationsData.grandTotalColumn.length
    ? calculationsData.grandTotalColumn[
        calculationsData.grandTotalColumn.length - 1
      ][1]
    : 0;

export const getLastSavedProposalProps = productsMap => {
  if (!productsMap) return undefined;

  const latestGoal =
    productsMap[Object.keys(productsMap)[Object.keys(productsMap).length - 1]];

  if (!latestGoal) return undefined;

  return latestGoal[latestGoal.length - 1];
};

export const getLatestSavedProposalProps = productsMap => {
  if (!productsMap) {
    return undefined;
  }

  let lastSavedGoal;

  Object.keys(productsMap).map(key => {
    const productLatestData = productsMap[key][productsMap[key].length - 1];

    if (
      !lastSavedGoal ||
      productLatestData?.timestamp > lastSavedGoal?.timestamp
    ) {
      lastSavedGoal = productLatestData;
    }
  });

  return lastSavedGoal;
};

export const getManagerPendoAccountId = managerData => {
  if (isPortalAdvisor()) {
    if (managerData.organization?.name?.includes("Asset Manager")) {
      return managerData.organization.name;
    } else {
      return (
        (managerData.organization?.name ??
          managerData.firmName ??
          managerData.orgName ??
          "Level") + " Asset Manager"
      );
    }
  } else if (isPortalAdmin()) {
    if (managerData.organization?.name?.includes("Asset Manager")) {
      return managerData.organization.name;
    } else {
      return (
        (managerData.organization?.name ??
          managerData.firmName ??
          managerData.orgName ??
          "Level") + " Asset Manager"
      );
    }
  } else {
    return (
      managerData.organization?.name ?? managerData.orgName ?? managerData._id
    );
  }
};

export const getManagerPendoPlanType = () => {
  if (isPortalAdmin()) {
    return "Admin";
  } else if (isPortalAdvisor()) {
    return "Advisor";
  } else {
    return "Pro Portal Advisor";
  }
};

export const getManagerPendoVisitorMetadata = managerData => {
  const id = managerData._id;
  const firstname = managerData.personalInfo?.firstName ?? "";
  const lastname = managerData.personalInfo?.lastName ?? "";
  const email = managerData._id;
  const plantype = getManagerPendoPlanType();

  return { id, firstname, lastname, email, plantype };
};

export const getManagedUsersTotalProposedAmount = managedUsers =>
  managedUsers?.length
    ? managedUsers.reduce((totalProposedAmount, it) => {
        const userTotalProposedAmount = it.valueMap?.productMap
          ? Object.keys(it.valueMap.productMap)
              .map(
                key =>
                  it.valueMap.productMap[key][
                    it.valueMap.productMap[key].length - 1
                  ].investmentAmount
              )
              .reduce((acc, value) => acc + value, 0)
          : (it.proposedAmount ?? 0);

        return totalProposedAmount + userTotalProposedAmount;
      }, 0)
    : 0;

export const getManuallyUpdatedOAGoalRecommendedProduct = ({
  customRiskTolerance,
  currentSelectedProduct,
  productsList,
}) => {
  const isAlts = currentSelectedProduct.name.toLowerCase().includes("alts");
  const isElite = currentSelectedProduct.name.toLowerCase().includes("elite");
  const isPeak = currentSelectedProduct.name.toLowerCase().includes("peak");
  const isStrategic = currentSelectedProduct.name
    .toLowerCase()
    .includes("strategic");

  if (
    isAlts &&
    isElite &&
    customRiskTolerance?.toLowerCase() === "preservation"
  ) {
    return productsList.find(product => {
      const productName = product.name.toLowerCase();
      return (
        productName.includes("alts") &&
        productName.includes("elite") &&
        productName.includes("conservative")
      );
    });
  } else if (
    isAlts &&
    isElite &&
    customRiskTolerance?.toLowerCase() !== "preservation"
  ) {
    return productsList.find(product => {
      const productName = product.name.toLowerCase();
      return (
        productName.includes("alts") &&
        productName.includes("elite") &&
        productName.includes(customRiskTolerance?.toLowerCase())
      );
    });
  } else if (
    isAlts &&
    !isElite &&
    customRiskTolerance?.toLowerCase() === "preservation"
  ) {
    return productsList.find(product => {
      const productName = product.name.toLowerCase();
      return (
        productName.includes("alts") &&
        !productName.includes("elite") &&
        productName.includes("conservative")
      );
    });
  } else if (
    isAlts &&
    !isElite &&
    customRiskTolerance?.toLowerCase() !== "preservation"
  ) {
    return productsList.find(product => {
      const productName = product.name.toLowerCase();
      return (
        productName.includes("alts") &&
        !productName.includes("elite") &&
        productName.includes(customRiskTolerance?.toLowerCase())
      );
    });
  } else if (isPeak && isElite) {
    return productsList.find(product => {
      const productName = product.name.toLowerCase();
      return (
        !productName.includes("alts") &&
        productName.includes("peak") &&
        productName.includes("elite") &&
        productName.includes(customRiskTolerance?.toLowerCase())
      );
    });
  } else if (isPeak && !isElite) {
    return productsList.find(product => {
      const productName = product.name.toLowerCase();
      return (
        !productName.includes("alts") &&
        productName.includes("peak") &&
        !productName.includes("elite") &&
        productName.includes(customRiskTolerance?.toLowerCase())
      );
    });
  } else if (isStrategic && isElite) {
    return productsList.find(product => {
      const productName = product.name.toLowerCase();
      return (
        !productName.includes("alts") &&
        productName.includes("strategic") &&
        productName.includes("elite") &&
        productName.includes(customRiskTolerance?.toLowerCase())
      );
    });
  } else if (isStrategic && !isElite) {
    return productsList.find(product => {
      const productName = product.name.toLowerCase();
      return (
        !productName.includes("alts") &&
        productName.includes("strategic") &&
        productName.includes(customRiskTolerance?.toLowerCase())
      );
    });
  } else if (isElite && !isStrategic && !isPeak) {
    return productsList.find(product => {
      const productName = product.name.toLowerCase();
      return (
        !productName.includes("alts") &&
        !productName.includes("strategic") &&
        productName.includes("elite") &&
        productName.includes(customRiskTolerance?.toLowerCase())
      );
    });
  }
};

export const handleCreateProposal = (email, state) => {
  const proposalData = state?.managedUsers?.find(it => it.email === email);

  if (
    !proposalData?.valueMap?.guideInitialData ||
    proposalData?.valueMap?.guideInitialData?.enableOnboardingQuestions
  ) {
    state.setKeyValue("activeEmail", email);
    state.openModal("openModalProposalGuide");
  } else {
    openProposalWithConfig({
      organization: state?.organization?.name,
      prospectType: state?.onboardingQuestionnaireType,
      userEmail: email,
    });
  }
};

export const isAffiliateBluepoint = affiliateUrl =>
  affiliateUrl === "bluepoint.com";

export const isAffiliateNewland = affiliateUrl =>
  affiliateUrl === "insurewithngi.com";

export const isDomainAstor = () => location.href.includes("astor");

export const isDomainBluepoint = () =>
  location.href.includes("bluepoint") || location.href.includes("biislczh0vr");

export const isDomainNewland = () =>
  location.href.includes("newland") ||
  location.href.includes("mymfinance") ||
  location.href.includes("o290t20jxfz");

export const isDomainHalo = () => location.origin.includes("halo");

export const isDomainLevel = () => location.href.includes("level");

export const isDomainMmi = () => location.href.includes("mmi");

export const isDomainOneascent = () => location.href.includes("oneascent");

export const isDomainRivershares = () => location.href.includes("rivershares");

export const isDomainSymetra = () => location.origin.includes("symetra");

export const isPortalAdmin = () =>
  location.pathname.includes("admin-portal") ||
  (!isAdminView() && localStorage.getItem("collection") === "OrgManager");

export const isPortalAdvisor = () =>
  location.pathname.includes("advisor-portal");

export const isPortalSuperadmin = () =>
  location.pathname.includes("super-admin");

export const isPortalOneascentAdvisor = () =>
  location.pathname.includes("oneascent-advisor-portal");

export const isPortalSuperAdmin = () =>
  location.pathname.includes("super-admin");

export const isManager = state => state.isManager;

export const isManagerAccessAffiliateOnly = accesses =>
  accesses?.affiliate &&
  !accesses?.level &&
  !accesses?.next &&
  !accesses?.proportal;

export const isManagerAccessLevelOnly = accesses =>
  !accesses?.affiliate &&
  accesses?.level &&
  !accesses?.next &&
  !accesses?.proportal;

export const isManagerAccessNextOnly = accesses =>
  !accesses?.affiliate &&
  !accesses?.level &&
  accesses?.next &&
  !accesses?.proportal;

export const isManagerAccessProportalOnly = accesses =>
  !accesses?.affiliate &&
  !accesses?.level &&
  !accesses?.next &&
  accesses?.proportal;

export const isManagerPortal = () =>
  isPortalAdmin() || isPortalAdvisor() || isPortalSuperAdmin();

export const isPortalNext = () =>
  location.host.includes("next") ||
  location.pathname.includes("/next-advisor-portal");

export const initializePendo = (visitor = {}, account = {}) => {
  if (PENDO_HOSTNAMES_LIST.includes(location.hostname)) {
    if (typeof pendo === "undefined") {
      (function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || [];
          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? "unshift" : "push"](
                    [m].concat([].slice.call(arguments, 0))
                  );
                };
            })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo");

        pendo.initialize({
          visitor,
          account,
        });
      })(process.env.REACT_APP_PENDO_KEY);
    } else {
      pendo.identify({
        visitor,
        account,
      });
    }
  }
};

export const isLockedByAdminView = config => {
  if (isAdminView() && !config?.managerAccess?.affiliate) {
    config?.showWarning &&
      config.showWarning("You can't save proposal data in Advisor View mode");
    return true;
  } else {
    return false;
  }
};

export const isAdminView = () => window.name === "adminView";

export const isDemoProposalUser = email =>
  [
    "demo@demo.com",
    "demo@demo-level.com",
    "demo@demo-riverfront.com",
    "demo@astor.com",
  ].includes(email);

export const isGrowthTypeRiskTolerance = value =>
  ["growth", "accumulate"].includes(value?.toLowerCase());

export const isGoalsDomain = () => location.hostname.includes("goals");

export const isManagerTypeIpq = type => type?.toLowerCase() === "ipq";

export const isOrgManager = userType => userType === "OrgManager";

export const isOrganizationAstor = organization =>
  organization?.toLowerCase()?.includes("astor");

export const isOrganizationBluepoint = organization =>
  organization?.toLowerCase()?.includes("bluepoint");

export const isOrganizationGoals = organization =>
  organization?.toLowerCase() === "goals";

export const isOrganizationLevel = organization =>
  organization?.toLowerCase()?.includes("level");

export const isOrganizationNewland = organization =>
  organization?.toLowerCase()?.includes("newland");

export const isOrganizationOneascent = organization =>
  organization?.toLowerCase()?.includes("oneascent");

export const isOrganizationRiggs = organization =>
  organization?.toLowerCase()?.includes("riggs");

export const isOrganizationRivershares = organization =>
  organization?.toLowerCase()?.includes("rivershares");

export const isPortalBluepoint = () => location.pathname.includes("bluepoint");

export const isPortalProposal = () => location.pathname.includes("proposal");

export const openProposalWithConfig = ({
  userEmail,
  userManagerEmail = "",
  organization = "Level",
  prospectType,
}) => {
  let proposalConfig = {};

  if (isOrganizationOneascent(organization)) {
    proposalConfig = {
      ...DEFAULT_PROPOSAL_INITIALIZATION_DATA,
      email: userEmail ?? "demo@demo.com",
      prospectType: prospectType ?? "WealthManagement",
    };
  } else if (isOrganizationRivershares(organization)) {
    proposalConfig = {
      contributions: 12000,
      currentAge: 40,
      currentSpouseAge: 0,
      email: userEmail ?? "demo@demo-riverfront.com",
      householdIncome: 0,
      investmentAmount: 100000,
      investmentDuration: 20,
      investmentObjective: "Accumulate",
      yearToStartWithdrawals: 10,
      organization: "Rivershares",
      prospectType,
      riskTolerance: "Growth",
      withdrawalLevel: "5%",
    };
  } else if (isOrganizationAstor(organization)) {
    proposalConfig = {
      contributions: 12000,
      currentAge: 40,
      currentSpouseAge: 0,
      email: userEmail ?? "demo@astor.com",
      householdIncome: 0,
      investmentAmount: 100000,
      investmentDuration: 20,
      investmentObjective: "growth",
      investmentsToAlignWith: "[]",
      investmentsToAvoid: "[]",
      yearToStartWithdrawals: 10,
      organization: "Astor",
      prospectType: prospectType ?? "WealthManagement",
      riskTolerance: "Growth",
      withdrawalLevel: "5%",
    };
  } else {
    proposalConfig = {
      email: userEmail ?? "demo@demo.com",
      contributions: 12000,
      riskTolerance: "Moderate",
      investmentObjective: "growth",
      investmentAmount: 100000,
      time: 25,
      organization,
    };
  }

  updateProductObjective(proposalConfig)
    .then(() =>
      openProposal({
        userEmail: userEmail ?? proposalConfig.email,
        userManagerEmail,
        organization,
      }).catch(console.log)
    )
    .catch(console.log);
};

export const setTenantSpecificHeadAttributes = organization => {
  if (isOrganizationOneascent(organization)) {
    setHeadAttributes({
      documentTitle: "OneAscent",
      faviconUrl: favicon_oneascent,
      metaDescription:
        "Chart your course to financial independence with OneAscent's advanced financial planning software.",
    });
  } else if (isOrganizationRivershares(organization)) {
    setHeadAttributes({
      documentTitle: "RiverShares",
      faviconUrl: favicon_rivershares,
      metaDescription:
        "Chart your course to financial independence with RiverShares advanced financial planning software.",
    });
  } else if (organization?.toLowerCase() === "mmi") {
    setHeadAttributes({
      documentTitle: "Thrive",
      faviconUrl: favicon_mmi,
      metaDescription: "Thrive software.",
    });
  } else if (isOrganizationAstor(organization)) {
    setHeadAttributes({
      documentTitle: "Astor",
      faviconUrl: favicon_astor,
      metaDescription:
        "Chart your course to financial independence with Astor advanced financial planning software.",
    });
  } else if (isOrganizationNewland(organization)) {
    setHeadAttributes({
      documentTitle: "Newland",
      faviconUrl: favicon_newland,
      metaDescription:
        "Chart your course to financial independence with Newland advanced financial planning software.",
    });
  } else {
    setHeadAttributes({
      documentTitle: "CleverCX",
      faviconUrl: favicon_level,
      metaDescription:
        "Chart your course to financial independence with CleverCX advanced financial planning software.",
    });
  }
};

export const isProposalUser = state =>
  location.pathname.includes("proposal") ||
  state.userType === "Prospect" ||
  state.userType === "ConvertedProspect";

export const getManagedUsers = state =>
  state.managedUsers
    ? state.managedUsers
        .filter(it => !it.isDeleted)
        .filter(
          elem =>
            elem.valueMap?.leadInitial?.isConverted ||
            elem.userType === "Registered" ||
            elem.userType === "ConvertedProspect"
        )
    : [];

export const getProspectUsers = state =>
  state.managedUsers
    ? state.managedUsers
        .filter(it => !it.isDeleted)
        .filter(
          elem =>
            elem.userType === "Prospect" ||
            (elem.entryPoint === "addedByUserManager" &&
              elem.userType === "Assigned")
        )
    : [];
