import { useContext, useMemo } from "react";
import State from "../../../../../../context";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { Flex, Statistic } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import StylesContainer from "./styles";

import {
  getAllManagedUsersOfAdvisors,
  getManagedUsersTotalProposedAmount,
} from "../../../../../../utils/helper/specialized";
import { getCurrencyValueWithRoundSign } from "../../../../../../utils/helper/general";

import {
  faArrowDownLong,
  faArrowUpLong,
} from "@fortawesome/free-solid-svg-icons";

dayjs.extend(quarterOfYear);

const StatisticContainer = ({ gap, suffix, title, value }) => (
  <Flex vertical>
    <Statistic title={title} value={value} suffix={suffix} />
    <div className="description">
      {gap > 0 ? (
        <h5 className="up">
          {Math.round(gap * 100) / 100}%
          <FontAwesomeIcon icon={faArrowUpLong} />
        </h5>
      ) : gap < 0 ? (
        <h5 className="down">
          {Math.round(gap * 100) / 100}%
          <FontAwesomeIcon icon={faArrowDownLong} />
        </h5>
      ) : (
        <h5>0%</h5>
      )}
      <span>than last quarter</span>
    </div>
  </Flex>
);

const SectionAdvisorsAnalytic = () => {
  const [state] = useContext(State);

  const calculation = useMemo(() => {
    if (!state.managedUserManagers) return {};

    const currentYear = +dayjs().format("YYYY");
    const currentQuarter = dayjs().quarter();

    const advisors = state.managedUserManagers;

    const currentQuarterAdvisors = advisors.filter(it => {
      const creationYear = +dayjs(it.userManager.createDateTime.$date).format(
        "YYYY"
      );

      const creationQuarter = dayjs(
        it.userManager.createDateTime.$date
      ).quarter();

      return creationYear === currentYear && creationQuarter === currentQuarter;
    });

    const advisorsGap =
      (currentQuarterAdvisors?.length /
        (advisors?.length - currentQuarterAdvisors?.length)) *
      100;

    const managedUsers = getAllManagedUsersOfAdvisors(
      state.managedUserManagers
    );

    const prospects = managedUsers
      .filter(it => !it.isDeleted)
      .filter(
        elem =>
          elem.userType === "Prospect" ||
          (elem.entryPoint === "addedByUserManager" &&
            elem.userType === "Assigned")
      );

    const clients = managedUsers
      .filter(it => !it.isDeleted)
      .filter(
        elem =>
          elem.userType !== "Prospect" &&
          !(
            elem.entryPoint === "addedByUserManager" &&
            elem.userType === "Assigned"
          )
      );

    return {
      advisors,
      advisorsGap,
      clients,
      prospects,
      totalProposedAum: getManagedUsersTotalProposedAmount(managedUsers),
    };
  }, [state.managedUserManagers]);

  return (
    <StylesContainer>
      <StatisticContainer
        gap={calculation.advisorsGap}
        title="Total Advisors"
        value={calculation.advisors?.length}
      />
      <StatisticContainer
        title="Total Clients"
        value={calculation.clients?.length}
      />
      <StatisticContainer
        title="Total Prospects"
        value={calculation.prospects?.length}
      />
      <StatisticContainer
        title="Total Proposed AUM"
        value={getCurrencyValueWithRoundSign(calculation.totalProposedAum)}
      />
      <StatisticContainer title="Active Proposals" value="N/A" />
      <StatisticContainer suffix="Monthly" title="Active Users" value="N/A" />
    </StylesContainer>
  );
};

export default SectionAdvisorsAnalytic;
