const ReportPageProposalDisclosure = ({ financialProductName }) => (
  <div id="reportPageProposalDisclosure">
    <img
      alt="Report Page Proposal Disclosure"
      src={`/assets/pdf/pdfOneAscent/factSheet/${financialProductName}/page2.jpg`}
      style={{
        width: 1230,
      }}
    />
  </div>
);

export default ReportPageProposalDisclosure;
