import { Button, Flex } from "antd";
import { RadialBar } from "@ant-design/plots";

import ProductStatsIcon from "./images/ProductStatsIcon";

const radialBarConfig = {
  height: 180,
  width: 180,
  xField: "name",
  yField: "percent",
  radius: 0.8,
  isPercent: true,
  innerRadius: 0.15,
  tooltip: false,
  showMarkers: false,
  colorField: "color",
  color: ({ color }) => {
    return color;
  },
  barBackground: {},
  barStyle: {
    lineCap: "round",
  },
  xAxis: false,
};

const radialSeries = [
  {
    name: "1",
    percent: 70,
    color: "#9B9B9B",
  },
  {
    name: "2",
    percent: 65,
    color: "#9B9B9B",
  },
  {
    name: "3",
    percent: 40,
    color: "#9B9B9B",
  },
];

const NextAnalyticsSection = () => (
  <Flex gap={23}>
    <Flex
      gap={10}
      style={{
        width: 470,
        background: "#ffffff",
        borderRadius: 8,
        boxShadow: "0px 0px 16px #0000000D",
      }}
    >
      <RadialBar {...radialBarConfig} data={radialSeries} />
      <Flex vertical>
        <h4
          style={{
            fontSize: 23,
            fontWeight: 300,
            color: "#03494E",
            margin: "38px 0 0",
          }}
        >
          Quarterly Goals
        </h4>
        <p
          style={{
            lineHeight: "19px",
            color: "#296469",
            margin: "0 0 14px",
          }}
        >
          Want to use investment products from MorningStar with your clients?
        </p>
        <Button
          shape="round"
          size="small"
          style={{
            width: 97,
            fontSize: 13,
            background: "#03494E",
            color: "#FFFFFF",
          }}
        >
          Learn More
        </Button>
      </Flex>
    </Flex>
    <div
      style={{
        width: 223,
        background: "#ffffff",
        padding: 22,
        borderRadius: 12,
        boxShadow: "0 0 16px #0000000d",
      }}
    >
      <Flex justify="end" style={{ marginBottom: "-14px" }}>
        <ProductStatsIcon />
      </Flex>
      <h4
        style={{
          fontSize: 23,
          fontWeight: 300,
          color: "#03494E",
          margin: 0,
        }}
      >
        Product Stats
      </h4>
      <p
        style={{
          lineHeight: "19px",
          color: "#296469",
          margin: "0 0 17px",
        }}
      >
        Want to use investment products from MorningStar with your clients?
      </p>
      <Button
        shape="round"
        size="small"
        style={{
          width: 97,
          fontSize: 13,
          background: "#03494E",
          color: "#FFFFFF",
        }}
      >
        Learn More
      </Button>
    </div>
  </Flex>
);

export default NextAnalyticsSection;
