import { ConfigProvider } from "antd";
import {
  legacyLogicalPropertiesTransformer,
  StyleProvider,
} from "@ant-design/cssinjs";
import locale from "antd/lib/locale/en_GB";

import {
  isAffiliateBluepoint,
  isAffiliateNewland,
  isOrganizationAstor,
  isOrganizationBluepoint,
  isOrganizationLevel,
  isOrganizationNewland,
  isOrganizationOneascent,
  isOrganizationRivershares,
  isPortalNext,
  isPortalProposal,
  isPortalSuperAdmin,
} from "../utils/helper/specialized";
import astorManagerTheme from "../ecosystem/astor/themes/manager";
import astorUserTheme from "../ecosystem/astor/themes/user";
import bluepointManagerTheme from "../ecosystem/bluepoint/themes/manager";
import bluepointUserTheme from "../ecosystem/bluepoint/themes/user";
import goalsTheme from "../ecosystem/goals/theme";
import levelManagerTheme from "../ecosystem/level/theme/manager";
import levelProposalTheme from "../ecosystem/level/theme/proposal";
import newlandManagerTheme from "../ecosystem/newland/theme/manager";
import newlandUserTheme from "../ecosystem/newland/theme/user";
import nextDefaultTheme from "../ecosystem/next/theme";
import oneascentManagerTheme from "../ecosystem/oneascent/theme/manager";
import oneascentUserTheme from "../ecosystem/oneascent/theme/user";
import proposalTheme from "../ecosystem/proposal/theme";
import riversharesManagerTheme from "../ecosystem/rivershares/themes/manager";
import riversharesUserTheme from "../ecosystem/rivershares/themes/user";
import superAdminTheme from "../ecosystem/super_admin/theme";

const UI = ({ children, themeConfig, state }) => {
  const affiliateUrl = state?.metaData?.keyValues?.AffiliateUrl;
  const organization = state?.orgName ?? state?.organization?.name;

  const getThemeConfig = () => {
    if (
      isOrganizationBluepoint(organization) ||
      isAffiliateBluepoint(affiliateUrl)
    ) {
      return state?.isManager ? bluepointManagerTheme : bluepointUserTheme;
    } else if (
      isOrganizationNewland(organization) ||
      isAffiliateNewland(affiliateUrl)
    ) {
      return state?.isManager ? newlandManagerTheme : newlandUserTheme;
    } else if (isOrganizationOneascent(organization)) {
      return state?.isManager ? oneascentManagerTheme : oneascentUserTheme;
    } else if (isOrganizationRivershares(organization)) {
      return state?.isManager ? riversharesManagerTheme : riversharesUserTheme;
    } else if (isOrganizationAstor(organization)) {
      return state?.isManager ? astorManagerTheme : astorUserTheme;
    } else if (isPortalNext()) {
      return nextDefaultTheme;
    } else if (isPortalSuperAdmin()) {
      return superAdminTheme;
    } else if (state?.isManager) {
      return levelManagerTheme;
    } else if (isPortalProposal()) {
      return isOrganizationLevel(organization)
        ? levelProposalTheme
        : proposalTheme;
    } else {
      return goalsTheme;
    }
  };

  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: {
          ...(themeConfig ?? getThemeConfig()),
        },
      }}
      locale={locale}
    >
      <StyleProvider
        hashPriority="high"
        transformers={[legacyLogicalPropertiesTransformer]}
      >
        {children}
      </StyleProvider>
    </ConfigProvider>
  );
};

export default UI;
