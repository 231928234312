import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Button,
  Col,
  Flex,
  Form,
  InputNumber,
  Row,
  Select,
  Switch,
  Tabs,
  theme,
  Typography,
} from "antd";

import StyledModal, { StyledButton } from "./styles";

import {
  PROPOSAL_GOAL_TYPE_LEVEL,
  RISK_TYPES_LEVEL,
} from "../../../level/constants";
import {
  PROPOSAL_GOAL_TYPE_ONEASCENT,
  RISK_TYPES_ONEASCENT,
} from "../../../oneascent/constant";
import {
  PROPOSAL_GOAL_TYPE_RIVERSHARES,
  RISK_TYPES_RIVERSHARES,
} from "../../../rivershares/constants";
import { RISK_TYPES_ASTOR } from "../../../astor/constants";

import {
  cleanCurrencyValue,
  cleanPercentValue,
  getCurrencyValue,
  getPercentValue,
  selectValue,
} from "../../../../utils/helper/general";
import {
  isGrowthTypeRiskTolerance,
  isOrganizationAstor,
  isOrganizationOneascent,
  isOrganizationRivershares,
} from "../../../../utils/helper/specialized";

import Close from "../../../../icon/CloseOutlined";
import OpenInNew from "../../../../icon/OpenInNew";
import { ReactComponent as AggressiveIcon } from "./images/aggressive_icon.svg";
import { ReactComponent as ModerateIcon } from "./images/moderate_icon.svg";
import { ReactComponent as ConservativeIcon } from "./images/conservative_icon.svg";
import { ReactComponent as ActiveIcon } from "./images/active_icon.svg";

const ModalGoalSettings = ({
  handleClose,
  handleUpdateGoal,
  loading,
  open,
  organization,
  proposalData,
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const isMobile = useMediaQuery({ maxWidth: token.screenSM });
  const [annuities_isOn, setAnnuities_isOn] = useState();
  const [otherIncome_isOn, setOtherIncome_isOn] = useState();
  const [socialSecurity_isOn, setSocialSecurity_isOn] = useState();
  const [riskTolerance, setRiskTolerance] = useState();
  const [investmentObjective, setInvestmentObjective] = useState();

  useEffect(() => {
    if (open && proposalData) {
      form.setFieldsValue({
        ...proposalData,
        annuities_isOn: !!proposalData.annuities_isOn,
        contributions: proposalData.contributions / 12,
        otherIncome_isOn: !!proposalData.otherIncome_isOn,
        socialSecurity_isOn: !!proposalData.socialSecurity_isOn,
      });
      setAnnuities_isOn(!!proposalData.annuities_isOn);
      setOtherIncome_isOn(!!proposalData.otherIncome_isOn);
      setSocialSecurity_isOn(!!proposalData.socialSecurity_isOn);
      setRiskTolerance(proposalData.riskTolerance);
      setInvestmentObjective(proposalData.investmentObjective);
    }
  }, [open]);

  const getInvestmentObjectiveOptions = () => {
    if (isOrganizationOneascent(organization))
      return PROPOSAL_GOAL_TYPE_ONEASCENT;
    else if (isOrganizationRivershares(organization))
      return PROPOSAL_GOAL_TYPE_RIVERSHARES;
    else return PROPOSAL_GOAL_TYPE_LEVEL;
  };

  const onFinish = updatedValues => {
    if (updatedValues.contributions) {
      updatedValues.contributions = updatedValues.contributions * 12;
    }

    handleUpdateGoal({
      ...proposalData,
      ...updatedValues,
    });
  };

  const onValuesChange = (value, values) => {
    setAnnuities_isOn(!!values.annuities_isOn);
    setOtherIncome_isOn(!!values.otherIncome_isOn);
    setSocialSecurity_isOn(!!values.socialSecurity_isOn);
    setInvestmentObjective(values.investmentObjective);
  };

  const handleMobileRiskToleranceButtonClick = selectedRiskTolerance => {
    form.setFieldValue("riskTolerance", selectedRiskTolerance);
    setRiskTolerance(selectedRiskTolerance);
  };

  const getOptions = () => {
    if (isOrganizationRivershares(organization)) return RISK_TYPES_RIVERSHARES;
    else if (isOrganizationOneascent(organization)) return RISK_TYPES_ONEASCENT;
    else if (isOrganizationAstor(organization)) return RISK_TYPES_ASTOR;
    else return RISK_TYPES_LEVEL;
  };

  const OverviewTab = () => (
    <Row gutter={[28, 28]}>
      <Col xs={24} sm={24} md={12} lg={12}>
        <Flex
          gap={isGrowthTypeRiskTolerance(investmentObjective) ? 32 : 19}
          vertical
        >
          <Form.Item label="Goal Type" name="investmentObjective">
            <Select
              options={getInvestmentObjectiveOptions()}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Length of Goal" name="investmentDuration">
            <InputNumber
              addonAfter={
                <Form.Item style={{ marginBottom: 0 }}>
                  <Select
                    options={[
                      {
                        label: "Years",
                        value: "years",
                      },
                      {
                        disabled: true,
                        label: "Quarters",
                        value: "quarters",
                      },
                      {
                        disabled: true,
                        label: "Months",
                        value: "months",
                      },
                    ]}
                    style={{ width: 120 }}
                    value="years"
                  />
                </Form.Item>
              }
              max={80}
              min={0}
              onClick={selectValue}
              step={1}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Target Amount" name="targetAmount">
            <InputNumber
              formatter={getCurrencyValue}
              max={100000000}
              min={0}
              onClick={selectValue}
              parser={cleanCurrencyValue}
              step={1000}
              style={{ width: "100%" }}
            />
          </Form.Item>
          {!isGrowthTypeRiskTolerance(investmentObjective) && (
            <Form.Item
              label="Begin Withdrawal of funds after"
              name="yearToStartWithdrawals"
            >
              <InputNumber
                formatter={value => (value ? `${value} years` : "")}
                max={80}
                min={1}
                onClick={selectValue}
                parser={value => value?.replace(" years", "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}
          <Form.Item label="Advisor Fee" name="advisorFee">
            <InputNumber
              formatter={getPercentValue}
              max={2}
              min={0}
              onClick={selectValue}
              parser={cleanPercentValue}
              step={0.1}
            />
          </Form.Item>
        </Flex>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12}>
        <Flex
          gap={isGrowthTypeRiskTolerance(investmentObjective) ? 32 : 19}
          vertical
        >
          <Form.Item
            label={token.proposal_copy_investment_amount}
            name="investmentAmount"
          >
            <InputNumber
              formatter={getCurrencyValue}
              max={100000000}
              min={0}
              onClick={selectValue}
              parser={cleanCurrencyValue}
              step={1000}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Monthly Contributions" name="contributions">
            <InputNumber
              formatter={getCurrencyValue}
              max={100000}
              min={0}
              onClick={selectValue}
              parser={cleanCurrencyValue}
              step={100}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Risk Tolerance" name="riskTolerance">
            {isMobile &&
            !isOrganizationOneascent(organization) &&
            !isOrganizationRivershares(organization) ? (
              <Flex gap={8} vertical>
                <StyledButton
                  className={riskTolerance === "Conservative" && "active"}
                  icon={
                    riskTolerance === "Conservative" ? (
                      <ActiveIcon />
                    ) : (
                      <ConservativeIcon />
                    )
                  }
                  onClick={() =>
                    handleMobileRiskToleranceButtonClick("Conservative")
                  }
                  shape="round"
                >
                  Conservative
                </StyledButton>
                <StyledButton
                  className={riskTolerance === "Moderate" && "active"}
                  icon={
                    riskTolerance === "Moderate" ? (
                      <ActiveIcon />
                    ) : (
                      <ModerateIcon />
                    )
                  }
                  onClick={() =>
                    handleMobileRiskToleranceButtonClick("Moderate")
                  }
                  shape="round"
                >
                  Moderate
                </StyledButton>
                <StyledButton
                  className={riskTolerance === "Aggressive" && "active"}
                  icon={
                    riskTolerance === "Aggressive" ? (
                      <ActiveIcon />
                    ) : (
                      <AggressiveIcon />
                    )
                  }
                  onClick={() =>
                    handleMobileRiskToleranceButtonClick("Aggressive")
                  }
                  shape="round"
                >
                  Aggressive
                </StyledButton>
              </Flex>
            ) : (
              <Select style={{ width: "100%" }} options={getOptions()} />
            )}
          </Form.Item>
          {!isGrowthTypeRiskTolerance(investmentObjective) && (
            <Form.Item
              label="Investment Withdrawal in Retirement"
              name="withdrawalLevel"
            >
              <InputNumber
                formatter={value => (value ? `${value}%` : "")}
                max={100}
                min={0}
                onClick={selectValue}
                parser={value => value.replace("%", "")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          )}
        </Flex>
      </Col>
    </Row>
  );

  const OtherIncomeTab = () => (
    <Flex gap={28} style={{ paddingTop: 10 }} vertical>
      <Flex gap={30}>
        <Form.Item name="socialSecurity_isOn" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Flex vertical>
          <Form.Item
            label="Social Security"
            name="socialSecurity"
            style={{ margin: 0 }}
          >
            <InputNumber
              disabled={!socialSecurity_isOn}
              formatter={getCurrencyValue}
              onClick={selectValue}
              parser={cleanCurrencyValue}
              style={{ width: 165 }}
            />
          </Form.Item>
          <Typography.Text style={{ fontSize: 12 }}>
            Need help?{" "}
            <Typography.Link
              style={{
                fontSize: 12,
                color: token.color_ui_text,
              }}
              href="https://www.ssa.gov/"
              target="_blank"
            >
              <u style={{ marginRight: 12 }}>Visit ssa.gov</u>
              <OpenInNew />
            </Typography.Link>
          </Typography.Text>
        </Flex>
      </Flex>
      <Flex align="end" gap={30}>
        <Form.Item name="annuities_isOn" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item label="Annuities" name="annuities" style={{ margin: 0 }}>
          <InputNumber
            disabled={!annuities_isOn}
            formatter={getCurrencyValue}
            onClick={selectValue}
            parser={cleanCurrencyValue}
            style={{ width: 165 }}
          />
        </Form.Item>
      </Flex>
      <Flex align="end" gap={30}>
        <Form.Item name="otherIncome_isOn" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          label="Other Income"
          name="otherIncome"
          style={{ margin: 0 }}
        >
          <InputNumber
            disabled={!otherIncome_isOn}
            formatter={getCurrencyValue}
            onClick={selectValue}
            parser={cleanCurrencyValue}
            style={{ width: 165 }}
          />
        </Form.Item>
      </Flex>
    </Flex>
  );

  return (
    <StyledModal
      closeIcon={<Close />}
      footer={
        <Flex justify="space-between">
          <Button onClick={handleClose} shape="round">
            Cancel
          </Button>
          <Button
            disabled={loading}
            form="goalSettingsForm"
            htmlType="submit"
            shape="round"
            type="primary"
          >
            Save & Update Goal
          </Button>
        </Flex>
      }
      onCancel={handleClose}
      open={open}
      title="Goal Settings"
      width={514}
    >
      <Form
        form={form}
        id="goalSettingsForm"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Tabs
          defaultActiveKey="overview"
          items={[
            {
              children: <OverviewTab />,
              key: "overview",
              label: "Overview",
            },
            {
              children: <OtherIncomeTab />,
              disabled: isGrowthTypeRiskTolerance(investmentObjective),
              key: "otherIncome",
              label: "Other Income",
            },
          ]}
          tabPosition="top"
        />
      </Form>
    </StyledModal>
  );
};

export default ModalGoalSettings;
