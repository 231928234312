import { useContext } from "react";
import dayjs from "dayjs";
import State from "../../../../../../context";
import { theme } from "antd";
import MenuActions from "../../../../../../atom/MenuActions";

import StyledTable from "./styles";

import { isPortalAdmin } from "../../../../../../utils/helper/specialized";
import { openFile } from "../../../../../../utils/request/manager";

import { CheckOutlined } from "@ant-design/icons";

const getFileActions = ({
  fileData,
  handleDeleteFile,
  handleFeaturedContentOptions,
  handleRenameFile,
}) =>
  isPortalAdmin()
    ? [
        {
          key: "downloadFile",
          label: <div onClick={() => openFile(fileData.id)}>Download File</div>,
        },
        {
          key: "renameFile",
          label: (
            <div onClick={() => handleRenameFile(fileData)}>Rename File</div>
          ),
        },
        {
          key: "featuredContentOptions",
          label: (
            <div onClick={() => handleFeaturedContentOptions(fileData)}>
              Featured Content Options
            </div>
          ),
        },
        {
          type: "divider",
        },
        {
          key: "deleteFile",
          label: (
            <div
              style={{ color: "red" }}
              onClick={() => handleDeleteFile(fileData)}
            >
              Delete File
            </div>
          ),
        },
      ]
    : [
        {
          key: "1",
          label: <div onClick={() => openFile(fileData.id)}>Download File</div>,
        },
      ];

const getFolderActions = ({
  handleAddSubfolder,
  handleDeleteFolder,
  handleRenameFolder,
  folderData,
}) =>
  [
    {
      hidden: folderData.parentFolder,
      key: "newFolder",
      label: (
        <span onClick={() => handleAddSubfolder(folderData?.id)}>
          New Folder
        </span>
      ),
    },
    {
      key: "renameFolder",
      label: (
        <span onClick={() => handleRenameFolder(folderData)}>
          Rename Folder
        </span>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "deleteFolder",
      label: (
        <span
          style={{ color: "red" }}
          onClick={() => handleDeleteFolder(folderData)}
        >
          Delete Folder
        </span>
      ),
    },
  ].filter(it => !it.hidden);

const getFolderColumns = ({
  handleAddSubfolder,
  handleDeleteFile,
  handleDeleteFolder,
  handleFeaturedContentOptions,
  handleRenameFile,
  handleRenameFolder,
}) => [
  {
    className: "cell-name",
    dataIndex: "name",
    key: "name",
    render: (name, allValues) => (
      <a onClick={() => openFile(allValues.id)}>{name}</a>
    ),
    title: "Name",
  },
  {
    dataIndex: "featureContentStatus",
    title: "Featured",
    key: "featureContentStatus",
    render: featureContentStatus =>
      featureContentStatus === "Visible" ? (
        <CheckOutlined style={{ fontSize: 24, color: "#4897D1" }} />
      ) : (
        ""
      ),
    width: 50,
  },
  {
    align: "right",
    dataIndex: "created",
    key: "created",
    title: "Created",
    width: 150,
  },
  {
    key: "id",
    render: (_, allValues) =>
      allValues.isFile ? (
        <MenuActions
          items={getFileActions({
            fileData: allValues,
            handleDeleteFile,
            handleFeaturedContentOptions,
            handleRenameFile,
          })}
        />
      ) : (
        isPortalAdmin() && (
          <MenuActions
            items={getFolderActions({
              folderData: allValues,
              handleAddSubfolder,
              handleDeleteFolder,
              handleRenameFolder,
            })}
          />
        )
      ),
    title: "",
    width: 40,
  },
];

const MaterialsTableView = () => {
  const { token } = theme.useToken();
  const [state] = useContext(State);

  const handleAddSubfolder = parentFolderId => {
    state.setKeyValue("parentFolderId", parentFolderId);
    state.openModal("openModalAddFolder");
  };

  const handleDeleteFile = fileData => {
    state.setKeyValue("activeFileData", fileData);
    state.openModal("openModalDeleteFile");
  };

  const handleDeleteFolder = folderData => {
    state.setKeyValue("activeFolderData", folderData);
    state.openModal("openModalDeleteFolder");
  };

  const handleFeaturedContentOptions = fileData => {
    state.setKeyValue("activeFileData", fileData);
    state.openModal("openModalFeaturedContentOptions");
  };

  const handleRenameFile = fileData => {
    state.setKeyValue("activeFileData", fileData);
    state.openModal("openModalRenameFile");
  };

  const handleRenameFolder = folderData => {
    state.setKeyValue("activeFolderData", folderData);
    state.openModal("openModalRenameFolder");
  };

  const getTableData = () => {
    if (state.marketingMaterialFolders?.length) {
      return state.marketingMaterialFolders
        .filter(it => !it.parentFolderId)
        .map(folder => ({
          children: [
            state.marketingMaterialFolders
              .filter(it => it.parentFolderId === folder.id)
              .map(subFolder => ({
                children:
                  subFolder.marketingMaterials?.map(file => ({
                    created: dayjs(file.createDateTime.$date).format(
                      "MM/DD/YYYY"
                    ),
                    featureContentStatus: file?.featureContentStatus,
                    folderData: subFolder,
                    id: file.id,
                    isFile: true,
                    key: `${subFolder.name}_${file.name}`,
                    name: file.name,
                    parentFolder: folder,
                  })) ?? [],
                created: dayjs(subFolder.createDateTime.$date).format(
                  "MM/DD/YYYY"
                ),
                id: subFolder.id,
                isFolder: true,
                key: `${folder.name}_${subFolder.name}`,
                name: subFolder.name,
                parentFolder: folder,
              })) ?? [],
            folder.marketingMaterials?.map(file => ({
              created: dayjs(file.createDateTime.$date).format("MM/DD/YYYY"),
              featureContentStatus: file?.featureContentStatus,
              folderData: folder,
              id: file.id,
              isFile: true,
              key: `${folder.name}_${file.name}`,
              name: file.name,
            })) ?? [],
          ]?.flat(),
          created: dayjs(folder.createDateTime.$date).format("MM/DD/YYYY"),
          id: folder.id,
          isFolder: true,
          isFolderParent: true,
          key: folder.name,
          marketingMaterials: folder.marketingMaterials,
          name: folder.name,
        }));
    }
  };

  const getRowClassName = rowData => {
    let classes = "";

    if (rowData.isFile) classes += " row-file";
    if (rowData.isFolder) classes += " row-folder";
    if (rowData.isFolderParent) classes += " row-folder-parent";

    return classes;
  };

  return (
    <StyledTable
      columns={getFolderColumns({
        handleAddSubfolder,
        handleDeleteFolder,
        handleRenameFolder,
        handleDeleteFile,
        handleFeaturedContentOptions,
        handleRenameFile,
      })}
      dataSource={getTableData()}
      loading={state.loading}
      pagination={false}
      rowClassName={getRowClassName}
      size="small"
      token={token}
    />
  );
};

export default MaterialsTableView;
