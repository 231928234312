import LogoRivershares from "../images/rivershares_logo_dark.svg";
import RiversharesNavBarMask from "../images/mask.svg";

export default {
  colorPrimary: "#4897D1",
  chart_colors: ["#4E96D1", "#1A2849", "#64C981", "#CCB681", "#00BDC4"],
  comparison_products_colors: ["#E48361", "#83C390", "#679BCE"],
  logo_src: LogoRivershares,
  sidebar_background: `url(${RiversharesNavBarMask}) #006395 right`,
  sidebar_icon_color_active: "#FFFFFF",
  tenant_color_primary: "#4897D1",
};
