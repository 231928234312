import styled from "styled-components";
import { Modal } from "antd";
import { MainContainer } from "@chatscope/chat-ui-kit-react";

import send_icon from "./images/send_message.svg";

export const StyledModal = styled(Modal)`
  margin: unset;
  max-height: 90vh;
  position: fixed;
  right: 20vw;
  top: 5vh;

  &.ant-modal {
    .ant-modal-close {
      right: 8px;
      top: 8px;
    }

    .ant-modal-content {
      padding: 48px 22px 19px;
    }

    .ant-modal-footer {
      margin: 0;
    }
  }

  @media screen and (max-width: 778px) {
    right: 5px;
    top: 5px;
  }
`;

export const StyledChatContainer = styled(MainContainer)`
  &.cs-main-container {
    border: unset;
    display: flex;
    flex-direction: column;
  }

  & .cs-chat-container .cs-message-input {
    border: unset;
  }

  & .cs-message-input__content-editor {
    background-color: #f0f3f4;
    color: #23292c;
    padding-right: 30px;
  }

  & .cs-message-input__content-editor-wrapper {
    min-height: 44px;
    height: fit-content;
    max-height: 99px;
    background-color: #f0f3f4;
    border-radius: 8px;
    margin: 0;
  }

  & .cs-message-input__tools {
    position: absolute;
    right: 7px;
    bottom: 10px;
  }

  & .cs-message--incoming .cs-message__content {
    background-color: #e6f4ef;
    border-radius: 12px 12px 12px 0;
    color: #1e1919;
    font-family: Inter, sans-serif;
    font-size: 14px;
    padding: 16px;
    user-select: text;
    white-space: break-spaces;
  }

  & .cs-message--outgoing .cs-message__content {
    font-family: Inter, sans-serif;
    font-size: 14px;
    color: #1e1919;
    background-color: #c9c8c8;
    padding: 16px;
    border-radius: 12px 12px 0 12px;
    user-select: text;
  }

  & .cs-button--send {
    align-self: center;
    width: 33px;
    height: 33px;
    color: #ffffff;
    background: ${props => props.send_message_button_background};
    padding: unset;
    border-radius: 50%;
    z-index: 1;
  }

  & .cs-button--send .fa-paper-plane {
    display: none;
  }

  & .cs-button--send::before {
    content: "";
    position: absolute;
    left: 2px;
    top: 4px;
    height: 24px;
    width: 24px;
    background: url(${send_icon});
  }

  & .cs-message:not(:only-child) {
    margin-top: 16px;
  }

  & .cs-message-list__scroll-wrapper {
    padding-left: 0;
  }
`;
