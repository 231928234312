const ReportPageProposalFactSheet = ({ financialProductName }) => (
  <div id="reportPageProposalFactSheet">
    <img
      alt="Report Page Proposal Fact Sheet"
      src={`/assets/pdf/pdfOneAscent/factSheet/${financialProductName}/page1.jpg`}
      style={{
        width: 1230,
      }}
    />
  </div>
);

export default ReportPageProposalFactSheet;
