import styled from "styled-components";
import { Menu } from "antd";

export const StyledMenu = styled(Menu)`
  &.ant-menu {
    background: transparent;
    width: 100%;

    &.ant-menu-light.ant-menu-root.ant-menu-vertical {
      border-right: unset;
    }

    .ant-menu-submenu-title {
      color: ${({ token }) => token.sidebar_menu_item_color};
    }

    .ant-menu-item {
      align-items: center;
      display: flex;
      background: transparent;
      border-radius: unset;
      color: ${({ token }) => token.sidebar_menu_item_color} !important;
      font-size: ${({ token }) => token.sidebar_menu_item_font_size};
      height: fit-content;
      line-height: 14px;
      margin: 0;
      min-height: 35px;
      padding: 0;
      width: 100%;

      &:not(:first-of-type) {
        margin-top: 8px;
      }

      &.ant-menu-item-selected::before {
        background: #ffffff;
        border-radius: 3px 0 0 3px;
        content: "";
        height: 100%;
        position: absolute;
        right: 0;
        width: 6px;
      }
    }
  }
`;
