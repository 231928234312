import styled from "styled-components";

const StylesContainer = styled.div`
  display: flex;
  gap: 28px;

  .description {
    align-items: end;
    display: flex;
    gap: 4px;

    h5 {
      font-size: 16px;
      margin: 0;

      &.up {
        color: #47951d;
      }

      &.down {
        color: #bc141a;
      }
    }

    span {
      color: #7e84a3;
      font-size: 12px;
    }
  }

  .ant-statistic {
    .ant-statistic-title {
      color: #5a607f;
      margin: 0;
    }

    .ant-statistic-content {
      color: #131523;
      font-size: 22px;
      font-weight: bold;
    }
  }
`;

export default StylesContainer;
