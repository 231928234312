import styled from "styled-components";
import { Layout } from "antd";

import mobile_overlay from "../images/dashboard_bg_mobile.svg";

const StyledLayout = styled(Layout)`
  flex-direction: column;
  height: 100vh;

  & .ant-layout-header {
    background: #ffffff;
    height: fit-content;
    padding: ${({ token }) => token.proposal_header_padding};
  }

  & .ant-layout-content {
    padding: 40px 51px 40px 40px;
  }

  &.ant-layout .ant-layout-sider {
    background: ${({ token }) => token.sidebar_background};
    background-position: left;
    padding: 56px 0;
  }

  &.ant-layout.ant-layout-has-sider > .ant-layout,
  &.ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: auto;
    background-color: #fff;
    overflow-x: auto;
  }

  @media screen and (min-width: 993px) {
    & .sidebar-button.active::after {
      content: "";
      position: absolute;
      right: -23px;
      width: 6px;
      height: 100%;
      border-radius: 3px 0 0 3px;
      background: #ffffff;
    }
  }

  @media screen and (max-width: 992px) {
    &.ant-layout.ant-layout-has-sider {
      flex-direction: column;
      height: 100vh;
      background-image: url(${mobile_overlay});
    }

    & .ant-layout-content {
      padding: 34px 20px;
    }

    &.ant-layout.ant-layout-has-sider > .ant-layout {
      width: auto;
      background: transparent;
      overflow: auto;
    }

    & .ant-layout-footer {
      background: transparent linear-gradient(121deg, #15284b 0%, #2a98d5 100%);
      padding: 12px 24px;
    }

    .ant-menu {
      align-items: end;
      display: flex;

      .ant-menu-item.ant-menu-item-selected::before {
        display: none;
      }
    }
  }
`;

export default StyledLayout;
